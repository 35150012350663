<template>
  <v-app-bar app clipped-left clipped-right>
    <v-toolbar-title
      class="no-padding"
      align="center"
      style="overflow: initial; width: 173px"
    >
      <img
        @click="getLogoImg"
        id="logo-img"
        :src="logo ? logo : '/apaly1.png'"
        class="apaly-pic pointer"
      />
    </v-toolbar-title>
    <v-divider class="header-divider-hide" vertical></v-divider>
    <div class="col-md-6 col-sm-6 header-hamb-icon mt-md-3 no-padding row">
      <i
        class="fas fa-bars top-2px"
        style="cursor: pointer; color: gray"
        @click="hideSideBar"
      ></i>

      <h2
        class="text-gray font-quicksand-semibold mb-4 ml-2 group-name"
        id="changeProDisplayName"
        @click="getDisplayName"
        v-html="displayName"
      ></h2>
    </div>
    <v-spacer />
    <div class="user-settings">
      <div class="font text-right font-quicksand-medium-12" v-if="user">
        {{ firstName }} {{ lastName }}
      </div>
      <div class="font-quicksand-medium-11" v-if="user">{{ user.email }}</div>
    </div>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40">
            <img
              style="object-fit: cover"
              id="profile-img"
              @click="changeProfileImg"
              :src="
                profileImg
                  ? profileImg
                  : 'https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png'
              "
              alt="John"
            />
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img
              style="object-fit: cover"
              :src="
                profileImg
                  ? profileImg
                  : 'https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png'
              "
              alt="John"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font" v-if="user"
              >{{ firstName }} {{ lastName }}
            </v-list-item-title>
            <!-- //<v-list-item-subtitle class="font" v-if="user">{{user.email}}</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item
          v-for="(item, i) in userprofile"
          :key="i"
          color="primary"
          :to="item.path"
        >
          <v-list-item-title 
            :class="listClass(item) + ' ml-5 mr-10 mt-1 mb-1'"
            @click="logout(item)">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import api from "../../../services/api";
export default {
  name: "Header",
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activeEntity: false,
    showLogo: true,
    showSearch: false,
    fav: true,
    displayName: "",
    logo: "",
    profileImg: "",
    accountSettings: false,
    firstName: "",
    lastName: "",
    userprofile: [
      {
        title: "My Profile",
      },
      {
        title: "Logout",
      },
    ],
  }),
  computed: {
    user() {
      let user = {
        firstName: "Bill",
        lastName: "Smith",
        email: "brian.mcnilty@usi.com",
      };
      if (localStorage.getItem("user")) {
        user = JSON.parse(localStorage.getItem("user"));
      }
      return user;
    },
  },
  created() {
    this.getLogoImg();
    this.getDisplayName();
    this.getEntityStatus()
  },
  methods: {
    listClass(item) {
        if (item.title === 'Logout') {
            return 'pointer'
        } else {
            return !this.activeEntity ? 'disabled' : 'pointer'
        }
    },
    getEntityStatus() {
        const user = JSON.parse(localStorage.getItem('user'));
        
        if ((user.entity && user.entity.status === "active") || user.superAdmin) {
            this.activeEntity = true;
        }
        return this.activeEntity;
    },
    getLogoImg() {
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;
      api()
        .get(`/providers/${provider}`)
        .then((res) => {
          if (res.data.provider.logo) {
            this.logo = res.data.provider.logo;
          } else {
            this.logo = null;
          }
          if (res.data.provider.primaryContact.profile) {
            this.profileImg = res.data.provider.primaryContact.profile;
          } else {
            this.profileImg = null;
          }
        });
    },
    getDisplayName() {
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;
      api()
        .get(`/providers/${provider}`)
        .then((res) => {
          this.displayName = res.data.provider.displayName
            ? res.data.provider.displayName
            : res.data.provider.companyName;
          this.firstName =
            res.data.provider.primaryContact &&
            res.data.provider.primaryContact.firstName
              ? res.data.provider.primaryContact.firstName
              : user.firstName;
          this.lastName =
            res.data.provider.primaryContact &&
            res.data.provider.primaryContact.lastName
              ? res.data.provider.primaryContact.lastName
              : user.lastName;
        });
    },
    changeProfileImg() {
      let user = JSON.parse(localStorage.getItem("user"));
      let provider = user.entity._id;
      api()
        .get(`/providers/${provider}`)
        .then((res) => {
          if (res.data.provider.primaryContact.profile) {
            this.profileImg = res.data.provider.primaryContact.profile;
          } else {
            this.profileImg = null;
          }
        });
    },
    hideSideBar() {
      this.$emit("expantSideBarOnHover");
    },
    logout(item) {
      if (item.title == "Logout") {
        localStorage.clear();
        this.$cookies.remove("token");
        this.$router.replace("/login");
      } else {
        if (!this.activeEntity) {
            return null
        }
        if (!this.$route.path.includes("/provider/profile")) {
          this.$router.push("/provider/profile");
        }
      }
    },
    showhideLogo: function () {
      this.showLogo = !this.showLogo;
    },
    searchbox: function () {
      this.showSearch = !this.showSearch;
    },
  },
};
</script>

<style>
</style>