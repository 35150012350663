<template>
  <div :class="windowWidth ? 'mainSideBarDiv' : 'mainSideBarMobile'">
    <v-navigation-drawer
      v-if="windowWidth"
      :width="!mini ? '800' : '69'"
      permanent
      class="white-sidebar"
    >
      <div>
        <v-divider></v-divider>
        <v-list-item
          two-line
          class="mt-3"
          :class="
            watchActiveItem == 'home' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('home')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-4 mt-lg-2 mt-md-1'
                : 'ml-4 mt-xl-4 mt-lg-2 mt-md-1'
            "
          >
            <svg
            class="mt-2"
              width="16"
              height="16"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7319 6.49L9.00356 0.659668L1.26809 6.49C1.10817 6.63755 1.01201 6.84157 1 7.05881V15.9622C1.00056 16.1464 1.07402 16.323 1.20432 16.4532C1.33462 16.5835 1.51119 16.657 1.69547 16.6575H6.33333V12.9268C6.33333 12.2197 6.61429 11.5415 7.11438 11.0415C7.61448 10.5414 8.29276 10.2605 9 10.2605C9.70724 10.2605 10.3855 10.5414 10.8856 11.0415C11.3857 11.5415 11.6667 12.2197 11.6667 12.9268V16.6597H16.3038C16.4882 16.6591 16.6648 16.5857 16.7952 16.4554C16.9256 16.3252 16.9993 16.1486 17 15.9643V7.05881C16.9877 6.84165 16.8916 6.63773 16.7319 6.49Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
                mt-2
              "
            >
              Home
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'solutions'
              ? 'pointer active'
              : 'pointer inactive'
          "
          @click="activeItem('solutions')"
          :disabled="!activeEntity() || (user.entity && !user.entity.marketplaceVisibility && !user.superAdmin)"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-2 mt-md-1 '
                : 'ml-4 mt-xl-3 mt-lg-2 mt-md-1'
            "
          >
          <svg class="mt-1" width="16" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8212 6.33988C11.8278 5.39326 11.5808 4.46215 11.106 3.64321C10.6312 2.82426 9.94579 2.14734 9.12102 1.68272C8.29624 1.21809 7.36214 0.982706 6.41567 1.00099C5.46921 1.01927 4.54489 1.29056 3.73868 1.78669C2.93247 2.28283 2.27375 2.98571 1.83091 3.82238C1.38807 4.65906 1.17725 5.59901 1.22034 6.54467C1.26343 7.49033 1.55887 8.40722 2.07596 9.20014C2.59306 9.99307 3.31298 10.6331 4.16097 11.0539V12.8216C4.16097 12.9779 4.22305 13.1278 4.33356 13.2383C4.44407 13.3488 4.59394 13.4109 4.75022 13.4109H8.28573C8.44201 13.4109 8.59189 13.3488 8.70239 13.2383C8.8129 13.1278 8.87498 12.9779 8.87498 12.8216V11.0539C9.75589 10.6199 10.4983 9.94879 11.0188 9.11604C11.5392 8.28329 11.8171 7.32189 11.8212 6.33988V6.33988Z" stroke="#707070" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.16016 16.3574H8.87416" stroke="#707070" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Marketplace
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'clients' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('clients')"
          :disabled="!activeEntity()"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-2 mt-md-1 '
                : 'ml-4 mt-xl-3 mt-lg-2 mt-md-1'
            "
          >
          <svg  class="mt-1"  width="16" height="16" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.33333 12.7334H2.06667C1.78377 12.7334 1.51246 12.8458 1.31242 13.0458C1.11238 13.2459 1 13.5172 1 13.8001V17.0001H6.33333V12.7334Z" fill="#707070" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15.9327 12.7334H11.666V17.0001H16.9993V13.8001C16.9993 13.5172 16.887 13.2459 16.6869 13.0458C16.4869 12.8458 16.2156 12.7334 15.9327 12.7334Z" fill="#707070" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.6007 8.46655H7.40065C7.11775 8.46655 6.84644 8.57893 6.6464 8.77897C6.44636 8.97901 6.33398 9.25032 6.33398 9.53322V16.9999H11.6673V9.53322C11.6673 9.25032 11.5549 8.97901 11.3549 8.77897C11.1549 8.57893 10.8835 8.46655 10.6007 8.46655Z" fill="#707070" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.13379 2.86667C7.13379 3.36174 7.33046 3.83653 7.68052 4.1866C8.03059 4.53667 8.50538 4.73333 9.00046 4.73333C9.49553 4.73333 9.97032 4.53667 10.3204 4.1866C10.6705 3.83653 10.8671 3.36174 10.8671 2.86667C10.8671 2.3716 10.6705 1.8968 10.3204 1.54673C9.97032 1.19667 9.49553 1 9.00046 1C8.50538 1 8.03059 1.19667 7.68052 1.54673C7.33046 1.8968 7.13379 2.3716 7.13379 2.86667V2.86667Z" fill="#707070" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11.3097 6.33324C11.0247 5.99863 10.6705 5.72989 10.2715 5.5456C9.87243 5.36131 9.43814 5.26587 8.99861 5.26587C8.55908 5.26587 8.1248 5.36131 7.72577 5.5456C7.32674 5.72989 6.97249 5.99863 6.6875 6.33324" fill="#707070"/>
            <path d="M11.3097 6.33324C11.0247 5.99863 10.6705 5.72989 10.2715 5.5456C9.87243 5.36131 9.43814 5.26587 8.99861 5.26587C8.55908 5.26587 8.1248 5.36131 7.72577 5.5456C7.32674 5.72989 6.97249 5.99863 6.6875 6.33324" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.8223 7.66672C12.8223 8.16179 13.0189 8.63658 13.369 8.98665C13.7191 9.33672 14.1939 9.53338 14.6889 9.53338C15.184 9.53338 15.6588 9.33672 16.0089 8.98665C16.3589 8.63658 16.5556 8.16179 16.5556 7.66672C16.5556 7.17164 16.3589 6.69685 16.0089 6.34678C15.6588 5.99671 15.184 5.80005 14.6889 5.80005C14.1939 5.80005 13.7191 5.99671 13.369 6.34678C13.0189 6.69685 12.8223 7.17164 12.8223 7.66672V7.66672Z" fill="#707070" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.9998 11.1333C16.616 10.6821 16.1083 10.3533 15.5395 10.1876C14.9708 10.0219 14.3659 10.0266 13.7998 10.2011" fill="#707070"/>
            <path d="M16.9998 11.1333C16.616 10.6821 16.1083 10.3533 15.5395 10.1876C14.9708 10.0219 14.3659 10.0266 13.7998 10.2011" stroke="#326AB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1.44336 7.66672C1.44336 7.91185 1.49164 8.15458 1.58545 8.38106C1.67926 8.60753 1.81676 8.81331 1.99009 8.98665C2.16343 9.15998 2.36921 9.29748 2.59568 9.39129C2.82216 9.4851 3.06489 9.53338 3.31003 9.53338C3.55516 9.53338 3.79789 9.4851 4.02437 9.39129C4.25084 9.29748 4.45662 9.15998 4.62996 8.98665C4.80329 8.81331 4.94079 8.60753 5.0346 8.38106C5.12841 8.15458 5.17669 7.91185 5.17669 7.66672C5.17669 7.42158 5.12841 7.17885 5.0346 6.95237C4.94079 6.7259 4.80329 6.52012 4.62996 6.34678C4.45662 6.17345 4.25084 6.03595 4.02437 5.94214C3.79789 5.84833 3.55516 5.80005 3.31003 5.80005C3.06489 5.80005 2.82216 5.84833 2.59568 5.94214C2.36921 6.03595 2.16343 6.17345 1.99009 6.34678C1.81676 6.52012 1.67926 6.7259 1.58545 6.95237C1.49164 7.17885 1.44336 7.42158 1.44336 7.66672V7.66672Z" fill="#707070" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 11.1333C1.28558 10.7995 1.63997 10.5313 2.03887 10.3472C2.43778 10.1631 2.87177 10.0674 3.31111 10.0667C3.61123 10.0669 3.90964 10.1119 4.19644 10.2003" fill="#707070"/>
            <path d="M1 11.1333C1.28558 10.7995 1.63997 10.5313 2.03887 10.3472C2.43778 10.1631 2.87177 10.0674 3.31111 10.0667C3.61123 10.0669 3.90964 10.1119 4.19644 10.2003" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Clients
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
       
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'invoices'
              ? 'pointer active'
              : 'pointer inactive'
          "
          @click="activeItem('invoices')"
          :disabled="!activeEntity()"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-2 mt-md-1 '
                : 'ml-4 mt-xl-3 mt-lg-2 mt-md-1'
            "
          >
            <svg
            class="mt-1"
              width="16"
              height="16"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2.6738L2.2 4.18767V7.7619C2.168 9.11254 3.7336 10.3224 5.1992 11.5642"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.58509 6.78734L6.00029 8.2372C6.08959 8.32888 6.19606 8.40207 6.31364 8.45259C6.43122 8.50311 6.5576 8.52997 6.68556 8.53164C6.81352 8.53331 6.94055 8.50976 7.05941 8.46233C7.17827 8.4149 7.28662 8.34452 7.37829 8.2552C7.46995 8.16589 7.54313 8.05939 7.59364 7.94179C7.64415 7.82419 7.67101 7.69779 7.67268 7.56981C7.67435 7.44182 7.6508 7.31477 7.60338 7.19589C7.55596 7.07701 7.48558 6.96863 7.39629 6.87696L5.19549 4.63096V3.46035C5.07625 2.7589 4.80958 2.09074 4.41309 1.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.2002 7.41145V17.1028C5.2002 17.4211 5.32662 17.7264 5.55167 17.9515C5.77671 18.1765 6.08194 18.303 6.4002 18.303H12.4002"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9996 18.3286L17.7996 16.8147V13.2413C17.8276 12.0739 16.6628 11.0121 15.3996 9.94472V14.1999L13.9996 12.766C13.8193 12.5809 13.5728 12.4749 13.3144 12.4716C13.0559 12.4682 12.8067 12.5676 12.6216 12.748C12.4365 12.9284 12.3306 13.1749 12.3272 13.4334C12.3239 13.6919 12.4233 13.9411 12.6036 14.1263L14.8076 16.3731V17.5429C14.9275 18.2432 15.1941 18.9101 15.59 19.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.782 15.3025H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.79961 12.3019H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6004 5.10064C16.6004 5.81278 16.3893 6.50893 15.9937 7.10105C15.5981 7.69317 15.0359 8.15467 14.3781 8.4272C13.7202 8.69972 12.9964 8.77103 12.2981 8.6321C11.5997 8.49316 10.9583 8.15024 10.4548 7.64668C9.95134 7.14312 9.60847 6.50155 9.46957 5.80309C9.33066 5.10463 9.40195 4.38067 9.67443 3.72273C9.9469 3.0648 10.4083 2.50246 11.0003 2.10682C11.5924 1.71117 12.2884 1.5 13.0004 1.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5996 2.39938L13.3196 5.67997C13.2777 5.72216 13.2278 5.75564 13.1729 5.77849C13.118 5.80134 13.0591 5.8131 12.9996 5.8131C12.9402 5.8131 12.8813 5.80134 12.8263 5.77849C12.7714 5.75564 12.7216 5.72216 12.6796 5.67997L11.6484 4.64778"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Invoices
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        
      </div>
      <div>
        <v-divider></v-divider>
        <v-list-item two-line v-if="!mini" class="mb-2">
          <span class="blue--text mt-1 ml-1 font-bold" style="font-size: 12px">
            Powered by:
          </span>

          <v-list-item-content
            class="no-padding pointer justify-center mt-2 mb-2"
          >
            <v-list-item-title
              class="d-flex mb-3 align-center text-center text-gray-darker"
            >
            <img src="/newapalylogoblue.png" alt="" class="mt-1 ml-1" width="110" height="45"/>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line v-else class="mt-4">
          <v-list-item-content class="no-padding pointer">
            <v-list-item-title
              class="d-flex mb-3 align-center text-center text-gray-darker"
            >
              <img
                src="/newapalylogoblue.png"
                class="ml-1"
                width="95"
                height="45"
                alt=""
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-navigation-drawer>
    <!-- Mobile -->
    <v-navigation-drawer v-else permanent class="white-sidebar-onmobile mt-3" style="width: 230px !important">
      <div class="ml-3">
        <v-divider></v-divider>
        <v-list-item
          two-line
          class="mt-5"
          :class="
            watchActiveItem == 'home' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('home')"
          :disabled="!activeEntity()"
        >
          <v-list-item-icon class="list-item-icon no-padding ml-4 ml-md-1 mt-4">
            <svg
              width="16"
              height="16"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.7319 6.49L9.00356 0.659668L1.26809 6.49C1.10817 6.63755 1.01201 6.84157 1 7.05881V15.9622C1.00056 16.1464 1.07402 16.323 1.20432 16.4532C1.33462 16.5835 1.51119 16.657 1.69547 16.6575H6.33333V12.9268C6.33333 12.2197 6.61429 11.5415 7.11438 11.0415C7.61448 10.5414 8.29276 10.2605 9 10.2605C9.70724 10.2605 10.3855 10.5414 10.8856 11.0415C11.3857 11.5415 11.6667 12.2197 11.6667 12.9268V16.6597H16.3038C16.4882 16.6591 16.6648 16.5857 16.7952 16.4554C16.9256 16.3252 16.9993 16.1486 17 15.9643V7.05881C16.9877 6.84165 16.8916 6.63773 16.7319 6.49Z"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
                mt-2
              "
            >
              Home
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'solutions' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('solutions')"
          :disabled="!activeEntity()"
        >
          <v-list-item-icon class="list-item-icon no-padding ml-4 ml-md-1 mt-3">
            <svg class="mt-1" width="16" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.8212 6.33988C11.8278 5.39326 11.5808 4.46215 11.106 3.64321C10.6312 2.82426 9.94579 2.14734 9.12102 1.68272C8.29624 1.21809 7.36214 0.982706 6.41567 1.00099C5.46921 1.01927 4.54489 1.29056 3.73868 1.78669C2.93247 2.28283 2.27375 2.98571 1.83091 3.82238C1.38807 4.65906 1.17725 5.59901 1.22034 6.54467C1.26343 7.49033 1.55887 8.40722 2.07596 9.20014C2.59306 9.99307 3.31298 10.6331 4.16097 11.0539V12.8216C4.16097 12.9779 4.22305 13.1278 4.33356 13.2383C4.44407 13.3488 4.59394 13.4109 4.75022 13.4109H8.28573C8.44201 13.4109 8.59189 13.3488 8.70239 13.2383C8.8129 13.1278 8.87498 12.9779 8.87498 12.8216V11.0539C9.75589 10.6199 10.4983 9.94879 11.0188 9.11604C11.5392 8.28329 11.8171 7.32189 11.8212 6.33988V6.33988Z" stroke="#707070" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.16016 16.3574H8.87416" stroke="#707070" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Marketplace
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'clients' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('clients')"
          :disabled="!activeEntity()"
        >
          <v-list-item-icon class="list-item-icon no-padding ml-4 ml-md-1 mt-3">
            <svg  class="mt-1"  width="16" height="16" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.33333 12.7334H2.06667C1.78377 12.7334 1.51246 12.8458 1.31242 13.0458C1.11238 13.2459 1 13.5172 1 13.8001V17.0001H6.33333V12.7334Z" fill="#707070" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M15.9327 12.7334H11.666V17.0001H16.9993V13.8001C16.9993 13.5172 16.887 13.2459 16.6869 13.0458C16.4869 12.8458 16.2156 12.7334 15.9327 12.7334Z" fill="#707070" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.6007 8.46655H7.40065C7.11775 8.46655 6.84644 8.57893 6.6464 8.77897C6.44636 8.97901 6.33398 9.25032 6.33398 9.53322V16.9999H11.6673V9.53322C11.6673 9.25032 11.5549 8.97901 11.3549 8.77897C11.1549 8.57893 10.8835 8.46655 10.6007 8.46655Z" fill="#707070" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.13379 2.86667C7.13379 3.36174 7.33046 3.83653 7.68052 4.1866C8.03059 4.53667 8.50538 4.73333 9.00046 4.73333C9.49553 4.73333 9.97032 4.53667 10.3204 4.1866C10.6705 3.83653 10.8671 3.36174 10.8671 2.86667C10.8671 2.3716 10.6705 1.8968 10.3204 1.54673C9.97032 1.19667 9.49553 1 9.00046 1C8.50538 1 8.03059 1.19667 7.68052 1.54673C7.33046 1.8968 7.13379 2.3716 7.13379 2.86667V2.86667Z" fill="#707070" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.3097 6.33324C11.0247 5.99863 10.6705 5.72989 10.2715 5.5456C9.87243 5.36131 9.43814 5.26587 8.99861 5.26587C8.55908 5.26587 8.1248 5.36131 7.72577 5.5456C7.32674 5.72989 6.97249 5.99863 6.6875 6.33324" fill="#707070"/>
              <path d="M11.3097 6.33324C11.0247 5.99863 10.6705 5.72989 10.2715 5.5456C9.87243 5.36131 9.43814 5.26587 8.99861 5.26587C8.55908 5.26587 8.1248 5.36131 7.72577 5.5456C7.32674 5.72989 6.97249 5.99863 6.6875 6.33324" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.8223 7.66672C12.8223 8.16179 13.0189 8.63658 13.369 8.98665C13.7191 9.33672 14.1939 9.53338 14.6889 9.53338C15.184 9.53338 15.6588 9.33672 16.0089 8.98665C16.3589 8.63658 16.5556 8.16179 16.5556 7.66672C16.5556 7.17164 16.3589 6.69685 16.0089 6.34678C15.6588 5.99671 15.184 5.80005 14.6889 5.80005C14.1939 5.80005 13.7191 5.99671 13.369 6.34678C13.0189 6.69685 12.8223 7.17164 12.8223 7.66672V7.66672Z" fill="#707070" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M16.9998 11.1333C16.616 10.6821 16.1083 10.3533 15.5395 10.1876C14.9708 10.0219 14.3659 10.0266 13.7998 10.2011" fill="#707070"/>
              <path d="M16.9998 11.1333C16.616 10.6821 16.1083 10.3533 15.5395 10.1876C14.9708 10.0219 14.3659 10.0266 13.7998 10.2011" stroke="#326AB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1.44336 7.66672C1.44336 7.91185 1.49164 8.15458 1.58545 8.38106C1.67926 8.60753 1.81676 8.81331 1.99009 8.98665C2.16343 9.15998 2.36921 9.29748 2.59568 9.39129C2.82216 9.4851 3.06489 9.53338 3.31003 9.53338C3.55516 9.53338 3.79789 9.4851 4.02437 9.39129C4.25084 9.29748 4.45662 9.15998 4.62996 8.98665C4.80329 8.81331 4.94079 8.60753 5.0346 8.38106C5.12841 8.15458 5.17669 7.91185 5.17669 7.66672C5.17669 7.42158 5.12841 7.17885 5.0346 6.95237C4.94079 6.7259 4.80329 6.52012 4.62996 6.34678C4.45662 6.17345 4.25084 6.03595 4.02437 5.94214C3.79789 5.84833 3.55516 5.80005 3.31003 5.80005C3.06489 5.80005 2.82216 5.84833 2.59568 5.94214C2.36921 6.03595 2.16343 6.17345 1.99009 6.34678C1.81676 6.52012 1.67926 6.7259 1.58545 6.95237C1.49164 7.17885 1.44336 7.42158 1.44336 7.66672V7.66672Z" fill="#707070" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1 11.1333C1.28558 10.7995 1.63997 10.5313 2.03887 10.3472C2.43778 10.1631 2.87177 10.0674 3.31111 10.0667C3.61123 10.0669 3.90964 10.1119 4.19644 10.2003" fill="#707070"/>
              <path d="M1 11.1333C1.28558 10.7995 1.63997 10.5313 2.03887 10.3472C2.43778 10.1631 2.87177 10.0674 3.31111 10.0667C3.61123 10.0669 3.90964 10.1119 4.19644 10.2003" stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Clients
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          two-line
          :class="
            watchActiveItem == 'invoices'
              ? 'pointer active'
              : 'pointer inactive'
          "
          @click="activeItem('invoices')"
          :disabled="!activeEntity()"
        >
          <v-list-item-icon class="list-item-icon no-padding ml-4 ml-md-1 mt-3">
            <svg
              width="16"
              height="16"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2.6738L2.2 4.18767V7.7619C2.168 9.11254 3.7336 10.3224 5.1992 11.5642"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.58509 6.78734L6.00029 8.2372C6.08959 8.32888 6.19606 8.40207 6.31364 8.45259C6.43122 8.50311 6.5576 8.52997 6.68556 8.53164C6.81352 8.53331 6.94055 8.50976 7.05941 8.46233C7.17827 8.4149 7.28662 8.34452 7.37829 8.2552C7.46995 8.16589 7.54313 8.05939 7.59364 7.94179C7.64415 7.82419 7.67101 7.69779 7.67268 7.56981C7.67435 7.44182 7.6508 7.31477 7.60338 7.19589C7.55596 7.07701 7.48558 6.96863 7.39629 6.87696L5.19549 4.63096V3.46035C5.07625 2.7589 4.80958 2.09074 4.41309 1.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.2002 7.41145V17.1028C5.2002 17.4211 5.32662 17.7264 5.55167 17.9515C5.77671 18.1765 6.08194 18.303 6.4002 18.303H12.4002"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9996 18.3286L17.7996 16.8147V13.2413C17.8276 12.0739 16.6628 11.0121 15.3996 9.94472V14.1999L13.9996 12.766C13.8193 12.5809 13.5728 12.4749 13.3144 12.4716C13.0559 12.4682 12.8067 12.5676 12.6216 12.748C12.4365 12.9284 12.3306 13.1749 12.3272 13.4334C12.3239 13.6919 12.4233 13.9411 12.6036 14.1263L14.8076 16.3731V17.5429C14.9275 18.2432 15.1941 18.9101 15.59 19.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.782 15.3025H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.79961 12.3019H7.59961"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.6004 5.10064C16.6004 5.81278 16.3893 6.50893 15.9937 7.10105C15.5981 7.69317 15.0359 8.15467 14.3781 8.4272C13.7202 8.69972 12.9964 8.77103 12.2981 8.6321C11.5997 8.49316 10.9583 8.15024 10.4548 7.64668C9.95134 7.14312 9.60847 6.50155 9.46957 5.80309C9.33066 5.10463 9.40195 4.38067 9.67443 3.72273C9.9469 3.0648 10.4083 2.50246 11.0003 2.10682C11.5924 1.71117 12.2884 1.5 13.0004 1.5"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5996 2.39938L13.3196 5.67997C13.2777 5.72216 13.2278 5.75564 13.1729 5.77849C13.118 5.80134 13.0591 5.8131 12.9996 5.8131C12.9402 5.8131 12.8813 5.80134 12.8263 5.77849C12.7714 5.75564 12.7216 5.72216 12.6796 5.67997L11.6484 4.64778"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Invoices
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

       
        <v-divider></v-divider>
        <v-list-group
          :value="false"
          :class="
            watchActiveItem == 'account' ||
            watchActiveItem == 'account/users' ||
            watchActiveItem == 'account/settings'
              ? 'pointer active-settings'
              : 'pointer  inactive'
          "
        >
          <template v-slot:activator>
            <v-list-item-icon
              @click="activeItem('account')"
              class="list-item-icon no-padding ml-4 ml-md-1 mt-3"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.84668 3.1472C7.84668 3.71667 8.07356 4.26282 8.47741 4.6655C8.88126 5.06818 9.429 5.2944 10.0001 5.2944C10.5713 5.2944 11.119 5.06818 11.5229 4.6655C11.9267 4.26282 12.1536 3.71667 12.1536 3.1472C12.1536 2.57773 11.9267 2.03158 11.5229 1.6289C11.119 1.22622 10.5713 1 10.0001 1C9.429 1 8.88126 1.22622 8.47741 1.6289C8.07356 2.03158 7.84668 2.57773 7.84668 3.1472V3.1472Z"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5654 10.5C12.8102 10.4961 13.0523 10.5513 13.2709 10.6611C13.4896 10.7709 13.6782 10.932 13.8208 11.1304C13.9633 11.3288 14.0555 11.5588 14.0893 11.8005C14.1232 12.0423 14.0978 12.2886 14.0152 12.5184"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 5.2944V19"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11.9746 4H18.3042C20.452 4 17.3414 9.1624 12.8708 7.44"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.0233 4H1.69532C-0.451716 4 2.65812 9.1624 7.1287 7.44"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.4342 10.5C7.03072 10.4959 6.64208 10.6516 6.35347 10.9327C6.06486 11.2139 5.89982 11.5977 5.89453 12C5.89666 12.4099 6.05844 12.8029 6.34572 13.096C6.633 13.3892 7.0233 13.5596 7.4342 13.5712H11.5389C11.9473 13.5712 12.3389 13.7329 12.6276 14.0209C12.9164 14.3088 13.0786 14.6992 13.0786 15.1064C13.0786 15.5136 12.9164 15.904 12.6276 16.192C12.3389 16.4799 11.9473 16.6416 11.5389 16.6416H8.71713C8.36869 16.6496 8.03769 16.7952 7.79695 17.0465C7.55621 17.2978 7.42544 17.6342 7.4334 17.9816C7.43239 18.1681 7.46966 18.3528 7.54293 18.5243C7.6162 18.6959 7.72391 18.8507 7.85944 18.9792"
                  stroke="#707070"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </v-list-item-icon>
            <v-list-item-title
              :class="
                watchActiveItem == 'account' ||
                watchActiveItem == 'account/users' ||
                watchActiveItem == 'account/settings'
                  ? 'pointer active-settings font-quicksand-medium-12 '
                  : 'pointer inactive font-quicksand-medium-12'
              "
              @click="activeItem('account')"
              class="text-gray-darker"
              >Account
            </v-list-item-title>
          </template>
          <div class="ml-16 text-gray-darker font-quicksand-medium-12">
         

            <div
              :class="
                watchActiveItem == 'account/users'
                  ? 'pointer active-settings'
                  : 'pointer inactive'
              "
              class="pointer mt-3 ml-2"
              @click="activeItem('account/users')"
            >
              Users
            </div>

            <div
              :class="
                watchActiveItem == 'account'
                  ? 'pointer active-settings'
                  : 'pointer inactive'
              "
              class="pointer mt-3 ml-2"
              @click="activeItem('account')"
            >
              Settings
            </div>
          </div>
        </v-list-group>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "Tables",
  data() {
    return {
      miniVersion: true,
      item: "home",
      items: [
        {
          icon: "fas fa-home",
          title: "Home",
        },
        {
          icon: "fas fa-users",
          title: "Clients",
        },
        {
          icon: "fas fa-cog",
          title: "Settings",
        },
      ],
      route: this.$route.path,
      user: {},
    };
  },
  props: {
    windowWidth: {
      type: Boolean,
    },
    mini: {
      type: Boolean,
    },
  },

  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    path() {
      return this.$route.path.replace(/%20/g, " ");
    },
    watchActiveItem() {
      if (this.$route.path.includes("/vendors/account/users")) {
        this.item = "account/users";
      } else if (this.$route.path.includes("/vendors/account/settings")) {
        this.item = "account/settings";
      } else if (this.$route.path.includes("/vendors/account/contract")) {
        this.item = "account/contract";
      } else if (this.$route.path.includes("/vendors/account/bank")) {
        this.item = "account/bank";
      } else if (this.$route.path.includes(this.$route.path.split("/")[2])) {
        this.item = this.$route.path.split("/")[2];
      }
      return this.item;
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('user'))
  },
  methods: {
    activeItem(tab) {
      if (this.$route.path != `/vendors/${tab}`) {
        this.$router.push(`/vendors/${tab}`);
      }
      if (!this.windowWidth) {
        this.$emit("closeSideBar");
      }
    },
    activeEntity() {
        return (this.user.entity && this.user.entity.status === "active") || this.user.superAdmin
    }
  },
};
</script>