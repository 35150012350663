<template>
  <div class="">
    <div class="row-height">
      <Header
          :title="'Invoice Details'"
          :exportButton="true"
          @export="exportFun"
      ></Header>
      <div class="row">
        <div class="col-12 mt-8 ml-8">
          Invoice information
        </div>
        <div class="col-12 col-md-6">
          <div class="text-gray  mt-3" style="align-items: baseline;">
            <div class="row" style="align-items: baseline">
              <div class="col-md-3 text-right">
                <span class="text-gray font-weight-bold ml-4">SOLUTION:</span>
              </div>
              <div class="col-md-6">
                <v-text-field class="input-control" :value="invoice.solution" disabled light dense outlined></v-text-field>
              </div>
            </div>
            <div class="row" style="align-items: baseline">
              <div class="col-md-3 text-right">
                <span class="text-gray font-weight-bold ml-4">STATUS:</span>
              </div>
              <div class="col-md-6">
                <v-text-field class="input-control" :value="invoice.status" disabled light dense outlined></v-text-field>
              </div>
            </div>
            <div class="row" style="align-items: baseline">
              <div class="col-md-3 text-right">
                <span class="text-gray font-weight-bold ml-4">INVOICE NUMBER:</span>
              </div>
              <div class="col-md-6">
                <v-text-field class="input-control" :value="invoice.invoiceNo" disabled light dense outlined></v-text-field>
              </div>
            </div>
            <div class="row" style="align-items: baseline">
              <div class="col-md-3 text-right">
                <span class="text-gray font-weight-bold ml-4">INVOICE DATE:</span>
              </div>
              <div class="col-md-6">
                <v-text-field class="input-control" :value="invoice.date | moment('MM/DD/YYYY')" disabled light dense outlined></v-text-field>
              </div>
            </div>
            <div class="row" style="align-items: baseline">
              <div class="col-md-3 text-right">
                <span class="text-gray font-weight-bold ml-4">TOTAL CHARGE (USD):</span>
              </div>
              <div class="col-md-6">
                <v-text-field class="input-control" :value="invoice.total" disabled light dense outlined></v-text-field>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-12 col-md-6">
          <div class="mt-3">
            <v-simple-table fixed-header height="70vh" class="no-padding tr-custom-height">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">VENDOR</th>
                  <th class="text-left">DESCRIPTION</th>
                  <th class="text-left">AMOUNT (USD)</th>
                </tr>
                </thead>

                <tbody v-if="invoice.items.length > 0" class="text-gray tr-custom-height">
                <tr class="text-gray" id="remove-hover" v-for="(item, index) in invoice.items" :key="index">
                  <td>{{ item.entity }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.amount }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
          <table id="tblData" style="display: none">
            <thead>
            <tr>
              <th class="text-left">VENDOR</th>
              <th class="text-left">DESCRIPTION</th>
              <th class="text-left">AMOUNT (USD)</th>
            </tr>
            </thead>

            <tbody v-if="invoiceList.length > 0" class="text-gray tr-custom-height">
            <tr class="text-gray" id="remove-hover" v-for="(item, index) in invoiceList" :key="index">
              <td>data</td>
              <td>data</td>
              <td>data</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import Header from "../Layout/HeaderPage.vue";
import api from "../../../../services/api";
export default {
  data() {
    return {
      showDetails: false,
      dialog: false,
      invoice: [],
      invoiceList: [],
      filterArray: [],
      singleItem: null,
      filterItems: false,
      load: false,
    };
  },
  components: {
    Header,
  },
  methods: {
    exportFun() {
      this.exportData("tblData");
    },
    exportData(tableID, filename = "") {
      let elt = document.getElementById(tableID);
      let downloadLink;
      let dataType = "application/vnd.ms-excel";
      let tableSelect = elt;
      let tableHTML = tableSelect.outerHTML.replace(/ /g, "%20");

      filename = filename ? filename + ".xls" : "excel_data.xls";
      downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        let blob = new Blob(["\ufeff", tableHTML], {
          type: dataType,
        });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        downloadLink.href = "data:" + dataType + ", " + tableHTML;
        downloadLink.download = filename;
        downloadLink.click();
      }
    },
  },
  created() {
    this.load = true;
    api().get(`/tpas/invoice/${this.$route.params.id}`)
        .then((res) => {
          if (res && res.data) {
            this.invoice = res.data
            this.load = false;
          }
        }).catch((err) => {
      this.load = false;
    });
  },
};
</script>
<style>
.border-blue-bottom {
  border-bottom: 6px solid #4072b7;
  margin-left: -1px;
  margin-right: -2px;
  margin-top: -1px;
}

.tr-custom-height > .v-data-table__wrapper > table > tbody > tr > td {
  height: 75px;
}
</style>