<template>
  <div class="row row-height justify-center col-12">
    <div class="organization-pending-info" v-show="!activeEntity">
      <img class="org-pending-icon" src="/svg/organizationPending.svg" />
      <span class="org-pending-title">Your organization is pending</span>
      <span class="org-pending-info">
        Thank you for registering with Apaly.
      </span>
      <span class="org-pending-info">
        Your account is being reviewed and you will be notified once your
        account is approved.
      </span>
    </div>
    <div v-if="activeEntity" class="col-md-8 col-12">
    <v-card class="text-center"
    v-if="companyName == '/'
    || taxId == '/' 
    || image == '/'
    || desc == '/' 
    || bankName == '/'
    || roundNo == '/'
    || accountNo == '/' "
    >
        <v-card-text class="success-modal pt-5">
        
        <div class="display mt-5">
            <span
            class="text-center font-18 mt-5 font-weight-bold color-black mt-5"
            >
            Complete your account information if you want your Vendor to be in the marketplace! 

            </span>
        </div>
        <div class="text-center text--white mt-10">
            <v-btn
            color="#16407A"
            class="submit-btn"
            @click="$router.push('/vendors/account')"
            >
            Complete account
            </v-btn>
        </div>
        </v-card-text>
    </v-card>
    <v-card class="text-center"  v-else>
        <v-card-text class="success-modal pt-5">
        
        <div class="display mt-5">
            <span
            class="text-center font-18 mt-5 font-weight-bold color-black mt-5"
            >
            Your account is completed! Now you can see your vendor in the marketplace.

            </span>
        </div>
        <div class="text-center text--white mt-10">
            <v-btn
            color="#16407A"
            class="submit-btn"
            @click="$router.push('/vendors/solutions')"
            >
            View Vendor
            </v-btn>
        </div>
        </v-card-text>
    </v-card>
    </div>
  </div>
</template>
<script>
import api from "@/services/api";
import successModal from "../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../TablesTemplate/ErrorDialog.vue";
export default {
  components: { successModal,errorModal },
  data() {
    return {
      companyName: "",
      taxId: "",
      image: "",
      desc: "",
      bankName: "",
      roundNo: "",
      accountNo: "",
      activeEntity: false,
    };
  },
  computed: {
    singleMember() {
      return this.$store.getters.getSingleMember;
    },
    user() {
      let user = {
        
      };
      if (localStorage.getItem("user")) {
        user = JSON.parse(localStorage.getItem("user"));
      }
      return user;
    },
  },
  created() {
   this.getInfo();
   this.getEntityStatus();
  },
  methods: {
    getInfo() {
      api()
        .get(`/vendors`)
        .then((res) => {
          if (res) {
            this.companyName = res.data.companyName ? res.data.companyName : "/";
            this.taxId = res.data.taxId ? res.data.taxId : "/";
            this.image=res.data.logo;
            this.desc = res.data.description ? res.data.description : "/";
            this.bankName = res.data.bank ? res.data.bank.name : "/";
            this.roundNo = res.data.bank ? res.data.bank.roundNo : "/";
            this.accountNo = res.data.bank ? res.data.bank.accountNo: "/";
          }
        });
    },
    getEntityStatus() {
        const user = JSON.parse(localStorage.getItem('user'));
        
        if ((user.entity && user.entity.status === "active") || user.superAdmin) {
            this.activeEntity = true;
        }
        return this.activeEntity;
    },
  }
};
</script>
<style> 
.submit-btn{
  width: 230px;
  color: white !important;
}

.organization-pending-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.org-pending-icon {
  width: 4vw;
  margin-top: 3.5vh;
  margin-bottom: 2.2vh;
}

.org-pending-title {
  font-size: 4.5vh;
  font-weight: bold;
  margin-bottom: 0.5vh;
}

.org-pending-info {
  font-size: 2vh;
  font-weight: bold;
  color: var(--inactive-color);
  line-height: 2.5vh;
}

</style>