<template>
  <v-app-bar app clipped-left clipped-right>
    <v-toolbar-title
      class="no-padding"
      align="left"
      style="overflow: initial; width:173px;"
    >
      <img
        id="logo-img"
        @click="$router.push('/dpc-providers/home')"
        :src="logo ? logo : '/newapalylogoblue.png'"
        class="apaly-pic pointer"
      />
    </v-toolbar-title>
    <v-divider class="header-divider-hide" vertical></v-divider>
    <div
      class="col-xl-3 col-lg-3 col-md-3 col-sm-6 header-hamb-icon mt-md-3 no-padding row"
    >
      <i
        class="fas fa-bars top-2px pt-1"
        style="cursor: pointer; color: gray"
        @click="hideSideBar"
      ></i>
      <h2
        class="text-gray font-quicksand-semibold mb-4 ml-2 group-name"
        style="font-size: 16px"
        v-html="
          user &&
          user.groupPortal &&
          user.groupPortal[0] &&
          user.companyName
            ? user.companyName
            : 'DPC Providers'
        "
      ></h2>
    </div>
    <div
      v-if="display"
      class="col-xl-3 col-lg-3 col-md-2 d-flex ml-4 mb-6"
      style="height: 40px"
      cols="12"
    >
      <span
        class="mt-1 text-gray font-weight bold mr-2 font-quicksand-semibold"
        style="font-size: 16px"
        >Location:</span
      >
      <div style="width: 70%" id="location-in-header" @click="getLocations">
        <v-select
          solo
          @change="setLoc(location)"
          v-model="location"
          :loc="location"
          item-text="name"
          item-value="id"
          placeholder="Select Location"
          :items="locations"
          dense
          single-line
        ></v-select>
      </div>
    </div>
    <v-spacer />
    <!--   <div class="user-settings">
      <div class="font text-right font-quicksand-medium-12" v-if="user">
        {{ user.firstName }} {{ user.lastName }}
      </div>
      <div class="font-quicksand-medium-11" v-if="user">{{ user.email }}</div>
    </div>

    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark icon v-on="on" class="mr-1">
          <v-avatar size="40">
            <img
              style="object-fit: cover"
              id="profile-img"
              @click="changeProfileImg"
              :src="
                profileImg
                  ? profileImg
                  : 'https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png'
              "
              alt="John"
            />
          </v-avatar>
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <img
              style="object-fit: cover"
              :src="
                profileImg
                  ? profileImg
                  : 'https://www.pngkit.com/png/full/128-1280585_user-icon-fa-fa-user-circle.png'
              "
              alt="John"
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font" v-if="user"
              >{{ user.firstName }} {{ user.lastName }}
            </v-list-item-title>
            //<v-list-item-subtitle class="font" v-if="user">{{user.email}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item
          v-for="(item, i) in userItems"
          :key="i"
          color="primary"
          :to="item.path"
        >
          <v-list-item-title class="pointer" @click="logout(item)">{{
            item.title
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu> -->

    <div class="user-settings">
      <div class="font text-right font-quicksand-medium-12 d-flex align-center">

        <!-- Check Eligibility button -->
        <v-btn class="ce_button" @click="openCheckPatientModal()" :disabled="!activeEntity || !marketplaceVisibility">Check Patient</v-btn>

        <!-- Check Patient -->
        <v-dialog v-model="checkPatientModal" width="600" persistent>
          <v-card class="dialog_container">
            <v-card-title class="d-flex justify-end dialog_x">
              <v-icon role="button" @click="closeCheckPatientModal()" v-text="'mdi-close'" slot="end"></v-icon>
            </v-card-title>

            <div class="dialog_content">
              <v-card-title class="dialog_title mb-5">Search Patient</v-card-title>
              <v-card-subtitle class="dialog_sub" >Enter first and last name to enroll new patient or view existing ones</v-card-subtitle >
              <div class="ce_checkName">
                <!-- Register new member -->
                <div class="register_new" v-if="!searchTerm || patients?.length === 0">
                  <button class="resgiter_new_btn" @click="checkPatient()">
                    Register new patient
                  </button>
                </div>

                <!-- Select patient -->
                <div class="dialog_label">Patient first name and last name</div>

                <!-- Select -->
                <v-select
                ref="mySelect"
                v-model="selectedMemberInfo"
                :items="patients"
                placeholder="Enter first name and last name"
                item-value="id"
                return-object
                class="custom-v-select"
                outlined dense
                no-data-text="No Member found"
                @click="focusSearchInput()"
                @change="selectedPatient()"
                v-click-outside="onClickOutside"
              >

                <template v-slot:prepend-item>
                  <div class="custom_select_input_container">
                    <!-- <input id="focusSearch" type="text" class="custom_select_input" v-model="searchTerm" placeholder="Search member here" @input="searchingEmployers" @keyup.enter="showPatientList()"/> -->
                    <input id="focusSearch" type="text" class="custom_select_input" v-model="searchTerm" placeholder="Search member here" @input="searchingPatients" @keyup.enter="noMember()"/>
                    <!-- <input id="focusSearch" type="text" class="custom_select_input" v-model="searchTerm" placeholder="Search member here" @input="searchingEmployers"/> -->
                  </div>

                  <!-- <div class="register_new" v-if="!searchTerm || patients?.length === 0">
                    <button class="resgiter_new_btn" @click="checkPatient()">
                      Register new patient
                    </button>
                  </div> -->
                </template>

                <!-- Show Selected Employer -->
                <template #selection="{ item }">
                  {{ item.firstName }}
                  <!-- {{ item && item.lastNames || item && item.firstName ? item.lastNames+" "+item.lastNames : searchTerm }} -->
                </template>

                <!-- List of Employers -->
                <template #item="{ item }">
                  <div class="ce_select_cont" @click="openProfile(item)">
                    <div class="ce_select_div">
                      {{ item.firstName }} {{ item.lastName }}
                    </div>
                    <div class="ce_gapLine"> - </div>
                    <div class="ce_select_div ce_select_gray">
                      {{ item.location }}
                    </div>
                    <!-- <div class="ce_gapLine"> - </div>
                    <div class="ce_select_div ce_select_gray">
                      {{ formatDate(item.lastVisit) }}
                    </div> -->
                    <div class="ce_select_div" :style="formatStatusBullet(item.status)">
                      <div class="ce_status_bullet">&bullet;</div>
                      {{ formatStatus(item.status) }}
                    </div>
                  </div>
                </template>

                <template #item-disabled="{ item }">
                  <div class="ce_select_cont" @click="openProfile(item)">
                    <div class="ce_select_div">
                      {{ item.firstName }} {{ item.lastName }}
                    </div>
                    <div class="ce_gapLine"> - </div>
                    <div class="ce_select_div ce_select_gray">
                      {{ item.location }}
                    </div>
                    <!-- <div class="ce_gapLine"> - </div>
                    <div class="ce_select_div ce_select_gray">
                      {{ formatDate(item.lastVisit) }}
                    </div> -->
                    <div class="ce_select_div float_right" :style="formatStatusBullet(item.status)">
                      <div class="ce_status_bullet">&bullet;</div>
                      {{ formatStatus(item.status) }}
                    </div>
                  </div>
                </template>
                
              </v-select>

              <div class="ce_patients_con" v-if="patientFound">
                <div class="ce_results_title">Results</div>
                <div class="ce_select_cont" v-for="(item, i) in patientsListResults" :key="`i`+i">
                  <div class="ce_select_div">
                      {{ item.firstName }} {{ item.lastName }}
                    </div>
                    <div class="ce_gapLine"> - </div>
                    <div class="ce_select_div ce_select_gray">
                      {{ item.location }}
                    </div>
                    <!-- <div class="ce_gapLine"> - </div>
                    <div class="ce_select_div ce_select_gray">
                      {{ formatDate(item.lastVisit) }}
                    </div> -->
                    <div class="ce_select_div">
                      <button class="ce_viewProfile" @click="openProfile(item)">
                        View Profile &nbsp;
                        <img style="width: 15px; height: auto; color: white;" :src="`/svg/icon_chevron_right_white.svg`"/>
                      </button>
                    </div>
                </div>
                <!-- {{ patientsListResults }} -->
              </div>

              <!-- OLD SELECT -->
              <!-- <v-autocomplete v-model="selectedMember" :items="members" color="white" solo
                :item-text="(i) => { return i.firstName + '  ' + i.lastName; }"
                :item-value="(i) => { return i; }"
              >
                <template v-slot:chip="{ props, item }">
                  {{ item.firstName }} {{ item.lastName }}
                  {{ item.dob | moment("MM/DD/YYYY") }}
                </template>

                <template v-slot:item="{ props, item }" >
                  <span @click="selectMember(item)"> {{ item.firstName }} {{ item.lastName }}</span>
                  {{ item.dob | moment("MM/DD/YYYY") }}
                </template>
                <template v-slot:no-data>
                  <div class="px-4">
                    <p>
                      No Member found 
                      <span @click="openNewMemberModal()" style="cursor:pointer;text-decoration:underline;color:blue;tex">
                        <br /> Check eligibility and register new member
                      </span>
                    </p>
                  </div>
                </template>
              </v-autocomplete> -->

              </div>
              <div class="dialog_buttons mt-10" style="display:flex;justify-content:center;align-items:center;" v-if="patientFound">
                or &nbsp;
                <v-btn class="dialog_single_button_2 dialog_btn_second" @click="checkPatient()">
                  Check eligibility and register a new member
                </v-btn>
              </div>
              <div class="dialog_buttons" v-else>
                <v-btn class="dialog_single_button dialog_btn_primary" @click="checkPatient()">
                  Check patient
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <!-- No patient found -->
        <v-dialog v-model="noMemberFound" width="500px" persistent>
          <v-card class="dialog_layout">
            <v-card-title class="d-flex justify-end">
              <v-icon role="button" @click="noMemberFound = false" v-text="'mdi-close'" ></v-icon>
            </v-card-title>
            <v-card-text class="dialog_content">
              <div class="dialog_title dialog_primary">
                No patient found
              </div>
              <div class="dialog_subtext dialog_second">
                Check eligibility to enroll this patient with your organization
              </div>
              <div class="dialog_buttons">
                <button class="dialog_single_button dialog_btn_primary" @click="checkPatient()" >
                  Check eligibility and register new member
                </button>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Loader: Checking elgibility -->
        <v-dialog v-model="checkingEligibility" width="500px" persistent>
          <v-card class="dialog_layout">
            <v-card-title class="d-flex justify-end">
              <v-icon role="button" @click="checkingEligibility = false" v-text="'mdi-close'" slot="end"></v-icon>
            </v-card-title>
            <v-card-text class="dialog_content">
              <div class="dialog_image">
                <v-progress-circular indeterminate :size="50" color="primary" class="text-center mt-5 mb-5"></v-progress-circular>
              </div>
              <div class="dialog_title dialog_second text-center">
                We are confirming your eligibility
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Patient Modal: Exists -->
        <v-dialog v-model="existPatientModal" width="400">
          <v-card v-if="selectedMember != null && getFullMemberStatus == true">
            <v-card-title>Member Account Summary</v-card-title>
            <v-card-subtitle
            style="padding:0 24px !important"
              >This Patient is an existing member. Here is the summary details of the
              member. <router-link to="/member/details">View more details</router-link>
            </v-card-subtitle>
            <v-card-text>
              <div
                class="member-information p-4 mb-5"
                style="background-color: #f5f6f8; padding: 20px"
              >
                <div class="d-flex">
                  <div class="member-information-labels">First Name</div>
                  <div class="member-information-values">
                    {{ selectedMember.firstName }}
                  </div>
                </div>
                <div class="d-flex">
                  <div class="member-information-labels">Middle Name</div>
                  <div class="member-information-values">
                    {{ selectedMember.middleName }}
                  </div>
                </div>
                <div class="d-flex">
                  <div class="member-information-labels">Last Name</div>
                  <div class="member-information-values">
                    {{ selectedMember.lastName }}
                  </div>
                </div>
                <div class="d-flex">
                  <div class="member-information-labels">Date of Birth</div>
                  <div class="member-information-values">
                    {{ selectedMember.dob | moment("MM/DD/YYYY") }}
                  </div>
                </div>
                <!-- <div class="d-flex">
                  <div class="member-information-labels">Plan Name</div>
                  <div class="member-information-values">
                    {{ selectedMember.plan.name }}
                  </div>
                </div> -->
                <div class="d-flex">
                  <div class="member-information-labels">Plan Id</div>
                  <div class="member-information-values">
                    {{ selectedMember.planId }}
                  </div>
                </div>
                <div class="d-flex">
                  <div class="member-information-labels">Status</div>
                  <div class="member-information-values">
                    {{ selectedMember.subscription?.status }} through
                    {{ selectedMember.subscription?.expiryDate | moment("MM/DD/YYYY") }}
                  </div>
                </div>
              </div>
              <span class="mb-5">Choose an action for the member:</span>
              <div class="member-information-actions d-flex mt-5">
                <div
                  @click="selectMemerAction('checkin')"
                  class="member-information-action"
                  :class="{ 'blue-active': memberAction == 'checkin' }"
                >
                  <img src="/svg/checkin.svg" class="mb-3" style="width: 30px" />
                  Check In now
                </div>
                <div
                  @click="selectMemerAction('future-visit-date')"
                  class="member-information-action"
                  :class="{ 'green-active': memberAction == 'future-visit-date' }"
                >
                  <img src="/svg/calendar-plus.svg" class="mb-3" style="width: 30px" />
                  Set Future Visit Date
                </div>
                <div
                  @click="selectMemerAction('report-visit')"
                  class="member-information-action"
                  :class="{ 'red-active': memberAction == 'report-visit' }"
                >
                  <img src="/svg/report-visit.svg" class="mb-3" style="width: 30px" />
                  Report Visit
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                block
                @click="memberActionModal()"
                >Next</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- New Patient -->
        <check-eligibility-new-member-modal @closeNewMemberModal="newPatientModal = false" v-if="newPatientModal" :newMemberDialog="newPatientModal"  />

        <!-- Subsriber's Info -->
        <subscriberInfoComp @closeSubscriberInfoComp="needSubsriberInfo = false" v-if="needSubsriberInfo" :addSubscriberInfo="needSubsriberInfo"  />

        <!-- Subsriber's Info -->
        <SelectClinic @closeSelectClinic="selectedClinic = false" v-if="selectedClinic" :selectClinic="selectedClinic" :patientInfo="patients"/>


        <!-- Check Now -->
        <v-dialog v-model="checkNowDialog" activator="parent" width="auto">
          <v-card class="col-md-12">
            <div class="row text-center justify-center orange-underline-wizard">
              <div class="text-center justify-center col-md-10 font-weight-bold mt-3 mb-3">
                Check Member In Now
              </div>
              <div class="col-md-1 mt-3 mr-3">
                <span class="text-end"
                  ><i
                    class="fa fa-times text-gray pointer"
                    style="font-size: 22px"
                    @click="checkNowDialog = false"
                  ></i>
                </span>
              </div>
            </div>
    
            <div v-if="selectedMember" class="row justify-center mt-10">
              <div style="width: 90%">
                <v-label class="text-gray">First Name</v-label>
                <v-text-field solo disabled v-model="selectedMember.firstName"></v-text-field>
              </div>
              <div style="width: 90%">
                <v-label class="text-gray">Last Name</v-label>
                <v-text-field solo disabled v-model="selectedMember.lastName"></v-text-field>
              </div>
              <div style="width: 90%" v-if="selectedMember.email">
                <v-label class="text-gray">Email</v-label>
                <v-text-field solo v-model="selectedMember.email"></v-text-field>
              </div>
              <div style="width: 90%" v-if="selectedMember.phone">
                <v-label class="text-gray">Phone</v-label>
                <v-text-field solo v-model="selectedMember.phone"></v-text-field>
              </div>
            </div>
    
            <div class="mt-5 justify-center row text-center">
              <div
                class="blue-success-modal mt-5 col-md-10 justify-center pointer mb-5"
                @click="checkMemberNow"
              >
              <img  src="/svg/checkin.svg"/>
              <div class="success-modal-blue-text mb-3 mt-2" style="font-size: 15px">
                Confirm / Enter Information and Check In
              </div>
              </div>
            </div>
          </v-card>
        </v-dialog>  

        <!-- Future Visit Date -->
        <v-dialog v-model="futureVisitDate" activator="parent" width="auto">
          <v-card class="col-md-12">
            <div class="row text-center justify-center orange-underline-wizard">
              <div class="text-center justify-center col-md-10 font-weight-bold mt-3 mb-3">
                <span class="ml-6"> Set a future visit date </span>
              </div>
              <div class="col-md-1 mt-3 mr-3">
                <span class="text-end"
                  ><i
                    class="fa fa-times text-gray pointer"
                    style="font-size: 22px"
                    @click="futureVisitDate = false"
                  ></i>
                </span>
              </div>
            </div>
    
            <div class="row justify-center mt-10">
              <v-card>
                <v-date-picker
                  header-color="black"
                  v-model.trim="futureDate"
                  show-adjacent-months
                  no-title
                  id="custom-date-picker"
                  scrollable
                  :show-current="true"
                  :color="'#084ba7'"
                  :weekday-format="parseDate"
                  class="custom-date-picker"
                  next-icon="fa-solid fa-caret-right"
                  prev-icon="fa-solid fa-caret-left"
                >
                </v-date-picker>
              </v-card>
            </div>
            <div class="justify-center row mt-2 text-center padding-on-mobile">
              <div
                class="green-success-modal col-md-10 mt-10 mb-5 justify-center pointer"
                @click="setFutureDate"
              >
                <img src="/svg/calendar-plus.svg" />
                <div class="success-modal-green-text mt-3 mb-2">Set Future Visit Date</div>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <!-- Menu -->
        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <svg
              v-bind="attrs"
              v-on="on"
              class="mr-4 pointer"
              width="28"
              height="30"
              viewBox="0 0 28 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0183 4.76923L10.9325 2.37846C11.0867 1.97368 11.3579 1.6255 11.7107 1.37953C12.0635 1.13357 12.4814 1.00128 12.9096 1H14.6529C15.0811 1.00128 15.499 1.13357 15.8518 1.37953C16.2046 1.6255 16.4758 1.97368 16.63 2.37846L17.5442 4.76923L20.648 6.57846L23.1567 6.19077C23.5744 6.13333 23.9995 6.20299 24.3781 6.39091C24.7566 6.57883 25.0715 6.87652 25.2826 7.24615L26.133 8.75385C26.3509 9.12937 26.4513 9.563 26.4209 9.99752C26.3906 10.432 26.2309 10.847 25.9629 11.1877L24.411 13.1908V16.8092L26.0054 18.8123C26.2734 19.153 26.4331 19.568 26.4634 20.0025C26.4938 20.437 26.3934 20.8706 26.1755 21.2462L25.3251 22.7538C25.114 23.1235 24.7991 23.4212 24.4206 23.6091C24.042 23.797 23.6169 23.8667 23.1992 23.8092L20.6906 23.4215L17.5867 25.2308L16.6725 27.6215C16.5184 28.0263 16.2471 28.3745 15.8943 28.6205C15.5415 28.8664 15.1237 28.9987 14.6954 29H12.9096C12.4814 28.9987 12.0635 28.8664 11.7107 28.6205C11.3579 28.3745 11.0867 28.0263 10.9325 27.6215L10.0183 25.2308L6.91445 23.4215L4.40584 23.8092C3.98813 23.8667 3.56299 23.797 3.18444 23.6091C2.8059 23.4212 2.49105 23.1235 2.27989 22.7538L1.42952 21.2462C1.21161 20.8706 1.11121 20.437 1.14158 20.0025C1.17194 19.568 1.33164 19.153 1.59959 18.8123L3.15153 16.8092V13.1908L1.55707 11.1877C1.28912 10.847 1.12942 10.432 1.09906 9.99752C1.0687 9.563 1.16909 9.12937 1.387 8.75385L2.23737 7.24615C2.44853 6.87652 2.76338 6.57883 3.14193 6.39091C3.52047 6.20299 3.94561 6.13333 4.36332 6.19077L6.87193 6.57846L10.0183 4.76923ZM9.52936 15C9.52936 15.852 9.77873 16.6848 10.2459 17.3932C10.7131 18.1016 11.3772 18.6538 12.1541 18.9798C12.9311 19.3058 13.786 19.3911 14.6108 19.2249C15.4355 19.0587 16.1932 18.6484 16.7878 18.046C17.3824 17.4436 17.7874 16.676 17.9514 15.8404C18.1155 15.0048 18.0313 14.1386 17.7095 13.3515C17.3877 12.5644 16.8427 11.8916 16.1435 11.4183C15.4443 10.945 14.6222 10.6923 13.7813 10.6923C12.6536 10.6923 11.5721 11.1462 10.7747 11.954C9.97733 12.7619 9.52936 13.8575 9.52936 15V15Z"
                stroke="#707070"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </template>
          <v-list class="dpc-menu-dropdown" style="margin-right: 0px">
            <v-list-item class="" v-for="(item, index) in items" :key="index">
              <v-list-item-title
                :class="(!activeEntity ? 'disabled' : 'pointer') + ' ml-5 mr-10 mt-1 mb-1'"
                :disabled="!activeEntity"
                @click="getRoute(item)"
                >{{ item.text }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Header Profile -->
        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-avatar size="35" v-bind="attrs" v-on="on" color="#4072B7">
              <span class="white--text" v-if="user && user.firstName && user.lastName">
                {{ user.firstName.charAt(0) }} {{ user.lastName.charAt(0) }}
              </span>
            </v-avatar>
          </template>
          <v-list class="dpc-menu-dropdown" style="margin-right: 0px">
            <v-list-item class="" v-for="(item, index) in customUserItems" :key="index">
              <v-list-item-title class="pointer ml-6 mr-6" @click="goToRoute(item.link)"
                >{{ item.text }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <successModal ref="success_modal" v-on:confirm="closeSuccessModal"></successModal>
    <!-- <removeDialog ref="dialog_delete" v-on:confirm="confirmRemove"></removeDialog> -->

    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"> </errorModal>
      </div>
    </div>
  </v-app-bar>
</template>
<script>
import { mapGetters } from "vuex";
import api from "../../../services/api";
import successModal from "../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../TablesTemplate/ErrorDialog.vue";
import moment from 'moment'

import CheckEligibilityNewMemberModal from "./SharedComponents/CheckEligibilityNewMemberModal.vue";
import subscriberInfoComp from "./SharedComponents/subsriberInfoAdditional.vue";
import SelectClinic from "./SharedComponents/selectClinic.vue";
export default {
  name: "Header",
  components: { 
    SelectClinic,
    subscriberInfoComp,
    CheckEligibilityNewMemberModal,
    successModal, 
    removeDialog, 
    errorModal,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activeEntity: false,
    marketplaceVisibility: false,
    selectedClinic: false,
    needSubsriberInfo: false,
    checkingEligibility: false,
    noMemberFound: false,
    searchTerm: '',
    selectedMemberInfo: null, // selectedEmployerInfo
    patients: [], // employers
    searchEmp: "",
    patientsCopy: [], // employersCopy
    allPatients: [], // allEmployers
    patientsListResults: [],
    patientFound: false,

    mobile: window.innerWidth,
    menu: "",
    hasSelectedMember: false,
    selectedMember: null,
    filteredMembers: [],
    member: {
      fullName: "",
      dob:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // lastName: "",
      // dob: "",
    },
    newMember:{
      firstName:'',
      lastName:'',
      dob:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      gender:'',
      memberId:'',
      groupId:'',
      payerId:'',
      planId:'',
      subscriberRelation:'',
      location:''//this.getLocationId,


    },
    members: [],
    memberAction: "checkin",
    existPatientModal: false,
    newPatientModal: false,
    checkPatientModal: false,
    showLogo: true,
    showSearch: false,
    fav: true,
    accountSettings: false,
    logo: null,
    profileImg: "",
    display: true,
    location: { name: "All", _id: null },
    windowWidth: window.innerWidth,
    locations: [],
    newLocations:[],
    showOverlay: false,
    errorModal: false,
    dialog: false,
    loader: false,
    activeMember: false,
    errorMessage: "",
    getFullMemberStatus:false,
    futureDate: "",
      futureVisitDate: false,
        checkNowDialog: false,
        futureVisitDate:false,

    iconVerify: "mdi-close-circle-outline",
    iconCheck: "mdi-check-circle-outline",
    subscriberItems: ["Self", "Spouse", "Dependent"],

    requiredRules: [(v) => !!v || "This Field is required"],
    valid: false,
    userprofile: [
      {
        title: "Logout",
      },
    ],
    userItems: [
      // {
      //   text: "Manage Account",
      //   link: "/dpc-providers/users",
      // },
      {
        text: "Sign Out",
        link: "/login",
      },
    ],
    items: [
      {
        text: "Settings",
        link: "/dpc-providers/account",
      },
      {
        text: "Users Management",
        link: "/dpc-providers/users",
      },
    ],
  }),
  computed: {
    returnFormatedDate(){
      return  moment(this.newMember.dob).format('MM/DD/YYYY') 
    },
    user() {
      let user = {
        firstName: "",
        lastName: "",
        email: "",
      };
      if (localStorage.getItem("user")) {
        user = JSON.parse(localStorage.getItem("user"));
      }
      return user;
    },
    customUserItems() {
      if (this.user.superAdmin) {
        return [
          ...this.userItems,
          {
            text: "Superadmin Dashboard",
            link: "/superadmin/providers",
          },
        ];
      } else return [...this.userItems];
    },
    ...mapGetters(["getLocationId"]),
  },
  mounted() {
    // Selecting Member
    // this.employersCopy = [...this.employers];
    this.patientsCopy = [...this.patients];

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    let Script = document.createElement("script");
    Script.setAttribute("src", "https://desk.zoho.com/portal/api/web/inapp/318353000002184017?orgId=675143780");
    Script.setAttribute("nonce", "");
    Script.setAttribute("defer", "defer");

    document.head.appendChild(Script);

  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  created() {
    this.getLogo();
    //this.logo = this.getLogo
    if (this.windowWidth < 600) {
      this.display = false;
    } else {
      this.display = true;
    }
    this.getLocations();
    this.getMembers();
    this.getEntityStatus();
  },
  watch: {
    // "$refs.mySelect.isMenuActive"(newVal, oldVal) {

    //     console.log('isMenuActive changed new:', newVal);
    //     console.log('isMenuActive changed old:', oldVal);
    //     if(newVal == false) {
    //       this.patientFound = false;
    //       console.log("No member");
    //       this.closeCheckPatientModal();
    //       this.noMemberFound = true;
    //     }
    // }
  },
  methods: {
    getRoute(item) {
        console.log('item', item);
        if (!this.activeEntity) {
            return null;
        }
        return this.$route.path != item.link ? this.$router.push(item.link) : '';
    },
    getEntityStatus() {
        const user = JSON.parse(localStorage.getItem('user'));
        
        if (user.entity && user.entity.status === "active" || user.superAdmin) {
            this.activeEntity = true;
        }
        if (user.entity && user.entity.marketplaceVisibility || user.superAdmin) {
            this.marketplaceVisibility = true;
        }

        return this.activeEntity;
    },
    // showPatientList() {
    //   console.log("Searched patients: ",this.patientsListResults);
    // },
    openProfile(member) {
      // console.log("Selected Member: ", member);
      this.closeCheckPatientModal();
      if(member && member.id) {
        this.$router.push("/dpc-providers/members/member/"+member.id).then(() => {
          window.location.reload();
        });
      }
      else {
        alert("No member with ID: " + member.id);
      }
    },
    closeCheckPatientModal() {
      this.checkPatientModal = false;
      this.searchTerm = "";
      this.patientsListResults = [];
      // this.patients = this.allPatients;
      this.patientFound = false;
      this.selectedMemberInfo = [];
    },
    formatStatusBullet(status) {
      let stat = status.toLowerCase();
      if(stat == "active") {
        return "color: #014D00;"
      }
      else if(stat == "pending") {
        return "color: #0069F3;"
      }
      else if(stat == "inactive") {
        return "color: #D90101;"
      }
    },
    formatStatus(status) {
      let stat = status.toLowerCase();
      if(stat == "active") {
        return "Active"
      }
      else if(stat == "pending") {
        return "Pending"
      }
      else if(stat == "inactive") {
        return "Inactive"
      }
    },
    formatDate(date) {
      if(date) {
        const d = new Date(date);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();
        return `${day}/${month}/${year}`;
      }
      else {
        return "No Date"
      }
    },
    noMember() {
      // Close select
      this.$refs.mySelect.isMenuActive = false;
      // If no members found
      if(this.patients.length < 1) {
        // this.searchTerm = "";
        this.patientFound = false;
        // console.log("No member");
        this.closeCheckPatientModal();
        this.noMemberFound = true;

        // Get members again
        this.getMembers();
      }
      else {
        this.patientFound = true;
      }

      if(this.searchTerm == "") {
        this.searchTerm = "";
        this.patientFound = false;
      }
      // console.log("patientFound", this.patientFound);
      // alert(this.selectedMemberInfo);
    },
    onClickOutside (event) {
      // console.log('Clicked outside. Event: ', event)
      // this.searchTerm = "";
      // if(this.employers.length < 1) {
      //   // this.searchTerm = "";
      //   this.patientFound = false;
      //   console.log("No member");
      //   this.closeCheckPatientModal();
      // }
    },
    focusSearchInput() {
      setTimeout(() => {
        document.getElementById("focusSearch").focus();
      }, 100);
    },
    searchingPatients(e) { // searchingEmployers
      // console.log("searchingEmployers", this.searchTerm);
      if (!this.searchTerm) {
        this.patientsCopy = this.patients;
      }

      this.patients = this.patientsCopy.filter((data) => {
        // console.log(data.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()));
        return data.firstName.toLowerCase().includes(this.searchTerm.toLowerCase()) || data.lastName.toLowerCase().includes(this.searchTerm.toLowerCase());
      });

      // Store search results
      this.patientsListResults = this.patients;

      if(this.searchTerm == '') {
        this.patientFound = false;
        this.patients = this.allPatients;
        this.patientsCopy = this.allPatients;
      }
    },
    selectedPatient() { // selectedEmployer
      // Selecting through list
      // alert();
      this.patients = this.allPatients;
      this.patientsCopy = this.allPatients;
    },
    setLoc(location){
      this.$store.commit('setLocationID', location);
      this.$emit('setLoc',location);

    },
    selectMemeberGender(gender){
      this.member.gender = gender
    },
    checkMemberNow() {
      let data = {
        firstName: this.selectedMember.firstName,
        lastName: this.selectedMember.lastName,
        email: this.selectedMember.email ? this.selectedMember.email : this.email,
        phone: this.selectedMember.phone ? this.selectedMember.phone : this.phone,
        location: this.getLocationId ? this.getLocationId : this.selectedMember.location,
        // memberId: this.memberId,
      };

      if (this.getLocationId) {
        data.location = this.getLocationId;
      }
      api()
        .post(`/dpcproviders/checkin/` + this.$route.params.id, data)
        .catch((err) => {
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
            this.futureVisitDate = false;
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
            this.futureVisitDate = false;
          }
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Success");
          }
        });
    },
    setFutureDate() {
      let data = {
        date: this.futureDate,
        //memberId: this.selectedMember._id,
      };
      if (this.getLocationId) {
        data.location = this.getLocationId;
      }
      api()
        .post(`/dpcproviders/checkfuture/` + this.selectedMember.id, data)
        .catch((err) => {
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if (res) {
            this.$refs.success_modal.openPopUp("Success");

          }
        });
    },
    parseDate(date) {
      const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    memberActionModal(){
      if(this.memberAction == 'checkin'){
        this.existPatientModal = false
        this.checkNowDialog = true
      }else if(this.memberAction == 'future-visit-date'){
        this.existPatientModal = false
        this.futureVisitDate = true
      }else{
        this.existPatientModal = false
        
      this.$store.commit("setSingleMemberProvider", this.selectedMember);
      this.$router.push("/dpc-providers/members/visit-reporting/"+this.selectedMember.id);
      }

    },
    openNewMemberModal() {
      this.newPatientModal = true;
      this.checkPatientModal = false;
    this.getLocationId ? this.newMember.location =  this.getLocationId : this.newMember.location =  ''
    },
    getMembers() {
      api()
        .get(`/dpcproviders/members`)
        .catch((err) => {
          if (err) {
            if (err.response.status == 500) {
              this.$refs.error_modal.openPopUp(
                "There was an error, system admin is notified. Please try again later."
              );
            } else {
              this.members = [];
              this.noDataForThisLocationMessage = err.response.data;
            }
          }
        })
        .then((res) => {
          this.members = res.data;
          // test
          this.patients = res.data;
          this.patientsCopy = res.data;
          this.allPatients = res.data;
          // console.log("getMembers: ", res.data);
        });
    },
    openCheckPatientModal() {
      this.checkPatientModal = true;
      this.selectedMember = null;
      this.filteredMembers = [];
      this.member.firstName = "";
      this.member.lastName = "";
      this.member.dob = "";
      this.hasSelectedMember = false;
    },
    selectMember(member) {
      this.selectedMember = member;
      this.hasSelectedMember = true;
    },
    selectMemerAction(type) {
      this.memberAction = type;
    },
    searchPatient() {
      this.filteredMembers = [];
      if (this.member.firstName.length > 3 || this.member.lastName.length > 3) {
        api()
          .get(
            `/dpcproviders/members/search?firstName=` +
              this.member.firstName +
              `&lastName=` +
              this.member.lastName
          )
          .then((res) => {
            this.filteredMembers = res.data;
          });
      }
    },
    checkPatient() {
      this.noMemberFound = false;
      if (!this.selectedMember) {
        this.newPatientModal = true;
        this.checkPatientModal = false;
        // this.getLocationId ? this.newMember.location =  this.getLocationId : this.newMember.location =  ''
      }
      // else {
      //   this.existPatientModal = true;
      //   this.checkPatientModal = false;
      //   this.getFullMember()
      // }
    },
    getFullMember(){
      api()
        .get(`/dpcproviders/member/` + this.selectedMember.id)
        .catch((err) => {
          if (err.response.status == 500) {
            this.$refs.error_modal.openPopUp(
              "There was an error, system admin is notified. Please try again later."
            );
          } else {
            this.$refs.error_modal.openPopUp(err.response.data);
          }
        })
        .then((res) => {
          if (res) {
           this.selectedMember.dob = res.data.memberInfo.dob
           this.selectedMember.middleName = res.data.memberInfo.middleName
           this.selectedMember.planId = res.data.employerPlanInfo.planId
           this.selectedMember.subscription = res.data.subscription
           this.getFullMemberStatus = true
          }
        });
    },
    getLocations() {
      api()
        .get("/dpcproviders/locations")
        .then((res) => {
          // this.$store.commit("setLocationID", null);
          this.locations = res.data;
          this.newLocations = res.data;
          this.locations.unshift({ name: "All", id: null });
        });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth < 959) {
        this.display = false;
      } else {
        this.display = true;
      }
    },

    getLogo() {
      api()
        .get(`/dpcproviders/account`)
        .then((res) => {
          if (res.data) {
            if (res.data.logo) {
              this.logo = res.data.logo;
            }
          }
        });
    },
    goToRoute(link) {
      if (link.includes("login")) {
        this.logout();
      } else {
        this.$route.path != link ? this.$router.push(link) : "";
      }
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/dpc-providers/members/");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },

    hideSideBar() {
      this.$emit("expantSideBarOnHover");
    },
    showhideLogo: function () {
      this.showLogo = !this.showLogo;
    },
    searchbox: function () {
      this.showSearch = !this.showSearch;
    },
    logout(item) {
      localStorage.clear();
      this.$cookies.remove("token");
      this.$router.replace("/login");
    },
  },
};
</script>
<style lang="scss">
.member-information-labels {
  width: 50%;
  color: #4f4f4f;
}
.member-information-values {
  color: black;
  flex: 1;
}
.member-information-action {
  background-color: #f5f6f8;
  color: darkblue;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 5px;
  margin: 5px;
  flex: 1;
  text-align: center;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  cursor: pointer;
}
.blue-active {
  border-color: #16407a;
  border: 2px solid;
}
.red-active {
  border-color: #ff4e00;
  border: 2px solid;
}
.green-active {
  border-color: #09951f;
  border: 2px solid;
}
.v-input--radio-group--column .v-input--radio-group__input{
  flex-direction: row !important;
  justify-content: flex-start;
}
.v-input--radio-group--column .v-radio:not(:last-child):not(:only-child){
  margin-right:20px;
  margin-bottom:0 !important
}
.v-input--selection-controls{
  margin-top:0 
}
.disabled {
    cursor: not-allowed;
}
</style>
