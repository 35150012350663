<template>
  <div class="row no-padding">
    <div class="organization-pending-info" v-show="!activeEntity">
      <img class="org-pending-icon" src="/svg/organizationPending.svg" />
      <span class="org-pending-title">Your organization is pending</span>
      <span class="org-pending-info">
        Thank you for registering with Apaly.
      </span>
      <span class="org-pending-info">
        Your account is being reviewed and you will be notified once your
        account is approved.
      </span>
    </div>
    <div
      class="row-height col-md-12 no-paddng"
      v-if="!isChildren && activeEntity"
      :class="showOverlay ? ' postion-relative' : ''"
    >
      <div
        v-if="showOverlay"
        class="wizard-absolute border-r-8 mt-4"
        @click="(dialog = false), (dialog1 = false), (showOverlay = false)"
      ></div>

      <div class="no-paddng d-block row">
        <div
          class="col-12 col-xl-8 col-lg-12 col-md-8 col-sm-12 row float-left pr-0"
        >
          <v-col
            class="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-xs-12 pr-lg-0"
          >
            <v-card class="" style="height: 15.5rem; cursor: pointer">
              <v-row class="justify-center mt-1">
                <h5
                  v-if="completed == 100"
                  class="text-center mt-4 font-quicksand-medium-18"
                >
                  Your account setup is completed
                </h5>
                <h5 v-else class="text-center mt-4 font-quicksand-medium-18">
                  Complete your account setup
                </h5>
              </v-row>
              <div class="pt-3 mb-2 ma-2 d-flex pa-2">
                <strong class="mr-1"> {{ completed }}% </strong>

                <v-progress-linear
                  color="#4072B7"
                  :value="completed"
                  height="15"
                  class="mt-1"
                  disabled
                  aria-readonly="true"
                  style="border-radius: 8px"
                >
                </v-progress-linear>
              </div>
              <div class="d-flex justify-space-between">
                <div class="col-12 mt-0 ml-0 p-0">
                  <span class="d-flex text-gray pb-1">
                    <span
                      @click="$router.push('/dpc-providers/account')"
                      role="button"
                    >
                      <img
                        v-if="accountCompleted"
                        src="/svg/filledCircle.svg"
                        width="18"
                        height="18"
                        class="mr-1 pt-1"
                      />
                      <img
                        v-else
                        src="/svg/emptyCircle.svg"
                        class="mr-1 pt-1"
                        width="18"
                        height="18"
                      />
                      Complete account information
                    </span>
                    <span class="ml-auto">
                      <a @click="$router.push('/dpc-providers/account')"
                        ><img
                          class="mb-0"
                          height="14"
                          src="/svg/right-arrow.svg"
                      /></a>
                    </span>
                  </span>
                  <span
                    class="d-flex text-gray pb-1"
                    @click="$router.push('/dpc-providers/locations')"
                    role="button"
                  >
                    <img
                      v-if="addLocations"
                      src="/svg/filledCircle.svg"
                      width="18"
                      height="18"
                      class="mr-1 pt-1"
                    />
                    <img
                      v-else
                      src="/svg/emptyCircle.svg"
                      class="mr-1 pt-1"
                      width="18"
                      height="18"
                    />
                    Add your locations
                    <span class="ml-auto">
                      <a @click="$router.push('/dpc-providers/locations')"
                        ><img
                          class="mb-0"
                          height="14"
                          src="/svg/right-arrow.svg"
                      /></a>
                    </span>
                  </span>
                  <span
                    class="d-flex text-gray pb-1"
                    @click="$router.push('/dpc-providers/providers')"
                    role="button"
                  >
                    <img
                      v-if="addProviders"
                      src="/svg/filledCircle.svg"
                      width="18"
                      height="18"
                      class="mr-1 pt-1"
                    />
                    <img
                      v-else
                      src="/svg/emptyCircle.svg"
                      class="mr-1 pt-1"
                      width="18"
                      height="18"
                    />
                    Add your providers
                    <span class="ml-auto">
                      <a @click="$router.push('/dpc-providers/providers')"
                        ><img
                          class="mb-0"
                          height="14"
                          src="/svg/right-arrow.svg"
                      /></a>
                    </span>
                  </span>
                  <span
                    class="d-flex text-gray pb-1"
                    @click="$router.push('/dpc-providers/rates')"
                    role="button"
                  >
                    <img
                      v-if="addRates"
                      src="/svg/filledCircle.svg"
                      width="18"
                      height="18"
                      class="mr-1 pt-1"
                    />
                    <img
                      v-else
                      src="/svg/emptyCircle.svg"
                      class="mr-1 pt-1"
                      width="18"
                      height="18"
                    />
                    Add rates
                    <span class="ml-auto">
                      <a @click="$router.push('/dpc-providers/rates')"
                        ><img
                          class="mb-0"
                          height="14"
                          src="/svg/right-arrow.svg"
                      /></a>
                    </span>
                  </span>
                  <span
                    class="d-flex text-gray"
                    @click="$router.push('/dpc-providers/solutions')"
                    role="button"
                  >
                    <img
                      v-if="participateInSolution"
                      src="/svg/filledCircle.svg"
                      width="18"
                      height="18"
                      class="mr-1 pt-1"
                    />
                    <img
                      v-else
                      src="/svg/emptyCircle.svg"
                      class="mr-1 pt-1"
                      width="18"
                      height="18"
                    />
                    Participate in solutions
                    <span class="ml-auto">
                      <a @click="$router.push('/dpc-providers/solutions')"
                        ><img
                          class="mb-0"
                          height="14"
                          src="/svg/right-arrow.svg"
                      /></a>
                    </span>
                  </span>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col
            class="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-xs-12 pr-lg-0"
            cols="12"
          >
            <v-card
              @click="openHeatMap()"
              class="justify-center text-center"
              style="height: 15.5rem; cursor: pointer"
            >
              <v-row class="mx-0 justify-center mt-1">
                <h5
                  class="text-center text-underline mt-4 font-quicksand-medium-18"
                >
                  Member Heat Map
                </h5>
              </v-row>
              <v-row class="mx-0 justify-center mt-3 mb-2">
                <h1 class="text-center text-underline mt-5">
                  <v-icon style="font-size: 45px; color: #0573f3"
                    >mdi-map-marker</v-icon
                  >
                </h1>
              </v-row>
              <v-row class="mx-5 justify-center my-0 mt-6">
                <h5
                  class="blue-text card-desc-responsive-dpc text-center font-quicksand-medium-18 mb-5 ma-2"
                >
                  Click to view how many members are within driving distance of
                  your clinics.
                </h5>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            v-for="(item, index) in cards"
            :key="index"
            class="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-xs-12 pr-lg-0"
            cols="12"
          >
            <v-card
              class="justify-center text-center"
              style="height: 15.5rem; cursor: pointer"
            >
              <v-row
                class="mx-0 justify-center mt-1"
                @click="filterItem(item.middle)"
              >
                <h5
                  class="text-center text-underline mt-4 font-quicksand-medium-18"
                >
                  {{ item.title }}
                </h5>
              </v-row>
              <v-row
                class="text-center justify-center mb-1"
                @click="filterItem(item.middle)"
              >
                <v-card-title>
                  <h1 class="dpc-cards" v-if="item.middle == 'expiringMembers'">
                    {{ expiringMembers }}
                  </h1>
                  <h1 class="dpc-cards" v-if="item.middle == 'visitDate'">
                    {{ visitDate }}
                  </h1>
                  <h1 class="dpc-cards" v-if="item.middle == 'checkIn'">
                    {{ checkIn }}
                  </h1>
                  <h1 class="dpc-cards" v-if="item.middle == 'pendingMembers'">
                    {{ pendingMembers }}
                  </h1>
                </v-card-title>
              </v-row>
              <v-row
                class="mx-5 justify-center my-0"
                @click="filterItem(item.middle)"
              >
                <h5
                  class="blue-text card-desc-responsive-dpc text-center font-quicksand-medium-18 mb-5 ma-2"
                >
                  {{ item.description }}
                </h5>
              </v-row>
            </v-card>
          </v-col>
        </div>

        <v-dialog
          v-model.trim="heatMapModal"
          width="100%"
          height="100%"
          class="success-modal"
        >
          <v-card>
            <span align="end" class="pt-2 pr-0">
              <p
                class="font-weight-bold text-gray pr-3 pt-2"
                @click="heatMapModal = false"
              >
                X
              </p>
            </span>
            <v-card-text
              style="padding: 0 !important"
              class="success-modal pt-5"
            >
              <iframe
                style="width: 100%; height: 600px"
                :src="mapLink"
              ></iframe>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import HeatMap from "./SharedComponents/HeatMap.vue";
import api from "../../../services/api";
export default {
  props: ["loc"],
  data() {
    return {
      accountCompleted: false,
      participateInSolution: false,
      addLocations: false,
      addProviders: false,
      addRates: false,
      center: {
        lat: 39.7837304,
        lng: -100.4458825,
      },
      locations: [],
      currentLocation: null,
      infoWindow: {
        position: { lat: 0, lng: 0 },
        open: false,
        template: "",
      },
      switchInfo: "",
      showOverlay: false,
      cards: [
        {
          title: "Pending Members",
          middle: "pendingMembers",
          description:
            "Number of members who have selected you,  but have not yet had their first visit.",
        },

        // {
        //   title: "Pending Visit Reports",
        //   middle: "visitDate",
        //   description:
        //     "List of members who were checked-in and are pending visit report ",
        // },
        {
          title: "Expiring Members",
          middle: "expiringMembers",
          description:
            "Members with an active subscription that is expiring within the next 60 days.",
        },
        // {
        //   title: "Check In",
        //   middle: "checkIn",
        //   description: "Quickly Check In those members who are setup to come in today.",
        // },
      ],
      requiredRules: [(v) => !!v || "This Field is required"],
      valid: false,
      dialog: false,
      dialog1: false,
      menu: "",
      checkIn: 0,
      pendingMembers: 0,
      expiringMembers: 0,
      visitDate: 0,
      errorMessage: "",
      editmsg: "",
      iconVerify: "mdi-close-circle-outline",
      iconCheck: "mdi-check-circle-outline",
      eligibilitySteps: [
        {
          text: "TPA Verified",
          show: false,
          verify: false,
        },
        {
          text: "Employer & Plan Verified",
          show: false,
          verify: false,
        },
        {
          text: "Platform and Claim System Exchange",
          show: false,
          verify: false,
        },
        {
          text: "Claim System to Platform",
          show: false,
          verify: false,
        },
      ],
      loader: false,
      statistics: null,
      heatMapModal: false,
      mapLink: "https://www.easymapmaker.com/map/patientsbyzip",
      companyInfo: true,
      activeEntity: false,
    };
  },
  computed: {
    isChildren() {
      let bool = false;
      if (this.$route.meta && this.$route.meta.isChildren) {
        bool = true;
      }
      return bool;
    },
    ...mapGetters([
      "getEligibilityMember",
      "getLocationId",
      "getSingleProviderMember",
    ]),
    completed() {
      const completedSteps = [
        this.accountCompleted,
        this.addLocations,
        this.addProviders,
        this.addRates,
        this.participateInSolution,
      ].filter((step) => step).length;
      return (completedSteps / 5) * 100;
    },
  },
  watch: {
    // '$store.state.locationID' : function(){
    //   console.log(this.$store.state.locationId);
    // }
  },
  created() {
    this.getStatistics();
    this.getValidations();
    this.getEntityStatus();
  },
  mounted() {
    this.setLocationLatLng();
  },
  methods: {
    openHeatMap() {
      this.heatMapModal = true;
    },
    openWindow(place) {
      this.infoWindow.position = { lat: place.lat, lng: place.lng };
      this.infoWindow.template = `<b>${place.label}</b>`;
      this.infoWindow.open = !this.infoWindow.open;
    },
    setPlace(loc) {
      this.currentLocation = loc;
    },
    setLocationLatLng: function () {
      navigator.geolocation.getCurrentPosition((geolocation) => {
        this.center = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude,
        };
      });

      this.locations = [
        {
          lat: 41.184458,
          lng: 20.635604,
          label: "Shum",
        },
        {
          lat: 41.178001,
          lng: 20.674299,
          label: "Struga",
        },
        {
          lat: 39.7837304,
          lng: -100.4458825,
          label: "United States",
        },
        {
          lat: 38.6529545,
          lng: -90.2411166,
          label: "St. Louis",
        },
        {
          lat: 41.3828939,
          lng: 2.1774322,
          label: "Barcelona",
        },
        {
          lat: -10.3333333,
          lng: -53.2,
          label: "Brazil",
        },
      ];
    },
    getStatistics() {
      api()
        .get("/dpcproviders/statistics")
        .catch((err) => {})
        .then((res) => {
          this.statistics = res.data;
          this.pendingMembers = res.data.statistics.pendingMembers;
          this.expiringMembers = res.data.statistics.expiringMembers;
        });
    },
    getValidations() {
      api()
        .get("/dpcproviders/account/validate")
        .catch((err) => {})
        .then((res) => {
          if (res) {
            this.accountCompleted = res.data.validations.account;
            this.participateInSolution = res.data.validations.solution;
            this.addLocations = res.data.validations.location;
            this.addProviders = res.data.validations.provider;
            this.addRates = res.data.validations.rate;
          }
        });
    },
    filterItem(title) {
      this.$store.commit("setFilterMember", title);
      setTimeout(() => {
        this.$router.push("/dpc-providers/members");
      }, 1000);
    },
    getEntityStatus() {
        const user = JSON.parse(localStorage.getItem('user'));
        
        if (user.entity && user.entity.status === "active" || user.superAdmin) {
            this.activeEntity = true;
        }

        return this.activeEntity;
    },
  },
};
</script>
<style>
.dpc-cards {
  text-underline-position: under;
  height: 55px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #0573f3;
  text-decoration: underline;
}
.padding-for-inputs {
  padding-top: 0px;
  padding-bottom: 0px;
}
.check-eligibility {
  border: 4px solid #4072b7;
  border-radius: 6px;
  min-height: 120px !important;
  text-align: center;
  justify-content: center;
}
.green-success-modal {
  border: 3px solid #09951f;
  padding: 0.5rem;
}
.blue-success-modal {
  border: 3px solid #084ba7;
  padding: 0.5rem;
}
.red-checkout-modal {
  border: 3px solid #ff4e00;
  padding: 0.5rem;
}
.red-checkout-modal-text {
  color: #ff4e00;
  font-weight: bold;
}
.success-modal-blue-text {
  color: #084ba7;
  font-weight: bold;
}
.success-modal-green-text {
  color: #09951f;
  font-weight: bold;
}
.error-modal-red-text {
  color: rgb(228, 0, 0);
  font-weight: bold;
}
.red-error-modal {
  border: 3px solid rgb(228, 0, 0);
  font-weight: bold;
}

.organization-pending-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.org-pending-icon {
  width: 4vw;
  margin-top: 3.5vh;
  margin-bottom: 2.2vh;
}

.org-pending-title {
  font-size: 4.5vh;
  font-weight: bold;
  margin-bottom: 0.5vh;
}

.org-pending-info {
  font-size: 2vh;
  font-weight: bold;
  color: var(--inactive-color);
  line-height: 2.5vh;
}

@media screen and (max-width: 1900px) {
  .padding-for-inputs {
    padding: 5px;
  }
}
</style>
