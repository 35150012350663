<template>
  <div v-if="memberData.subscription" class="col-12 col-md-12 mt-2">
    <v-form ref="form" class="row mt-2">
        <div class="col-12 col-md-9">
            <div class="row">
                <div class="col-12 col-md-4">
                    <h3>Subscription Details</h3>
                    <p>Enter some basic company information</p>
                </div>
                <div class="col-12 col-md-8">
                    <div class="row">
                        <!-- <div class="col-12 col-md-6">
                            <label class="font" for="">Subscription ID</label>
                            <p class="font-weight-bold">{{memberData.subscription.id}}</p>
                            <v-text-field
                            hide-details
                            disabled
                            v-model.trim="memberData.subscription.id"
                            solo
                            ></v-text-field>
                        </div> -->
                        <div class="col-12 col-md-6">
                            <label class="font" for="">Status</label>
                            <p class="font-weight-bold">{{memberData.subscription.status}}</p>
                            <!-- <v-text-field
                            disabled
                            hide-details
                            v-model.trim="memberData.subscription.status"
                            solo
                            ></v-text-field> -->
                        </div>
                         <div class="col-12 col-md-6">
                            <label class="font" for="">Location Attribution </label>
                            <p class="font-weight-bold">{{memberData.memberInfo.location}}</p>
                            <!-- <v-text-field
                            hide-details
                            disabled
                            v-model.trim="memberData.subscription.id"
                            solo
                            ></v-text-field> -->
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="font" for="">Scheduled Payments</label>
                            <p class="font-weight-bold">{{memberData.subscription.scheduledPayements}}</p>
                            <!-- <v-text-field
                            disabled
                            hide-details
                            v-model.trim="memberData.subscription.scheduledPayements"
                            solo
                            ></v-text-field> -->
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="font" for="">Payement Recurrence</label>
                            <p class="font-weight-bold">{{memberData.subscription.recurrence}}</p>
                            <!-- <v-text-field
                            disabled
                            hide-details
                            v-model.trim="memberData.subscription.recurrence"
                            solo
                            ></v-text-field> -->
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="font" for="">Start Date</label>
                            <p class="font-weight-bold">{{memberData.subscription.startDate | moment('MM/DD/YYYY')}}</p>
                            <!-- <v-text-field
                            disabled
                            hide-details
                            v-model.trim="memberData.subscription.startDate"
                            solo
                            ></v-text-field> -->
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="font" for="">End Date</label>
                            <p class="font-weight-bold">{{memberData.subscription.expiryDate | moment('MM/DD/YYYY')}}</p>
                            <!-- <v-text-field
                            disabled
                            hide-details
                            v-model.trim="memberData.subscription.expiryDate"
                            solo
                            ></v-text-field> -->
                        </div>
                    </div>
                </div>
            </div>
            <v-divider class="mt-10 mb-10"></v-divider>
            <div class="row">
                <div class="col-12 col-md-4">
                    <h3>Invoice Details</h3>
                    <p>Enter the address of your headquarters</p>
                </div>
                <div class="col-12 col-md-8">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <label class="font" for="">Active Invoice</label>
                            <p class="font-weight-bold">{{memberData.subscription.invoiceNo}}</p>

                            <!-- <v-text-field
                            hide-details
                            disabled
                            v-model.trim="memberData.subscription.invoiceID"
                            solo
                            ></v-text-field> -->
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="font" for="">Total Subscription Amount (yearly)</label>
                            <p class="font-weight-bold">$ {{memberData.subscription.total.toFixed(2)}}</p>
                            <!-- <v-text-field
                            disabled
                            hide-details
                            v-model.trim="memberData.subscription.total"
                            solo
                            ></v-text-field> -->
                        </div>
                        <div class="col-12">

                            <h4>Processing Fees</h4>
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="font" for="">Apaly Processing Fee </label>
                        </div>
                        <div class="col-12 col-md-6">
                            <p class="font-weight-bold">{{memberData.subscription?.apalyFee.amount}} 
                                <span v-if="memberData.subscription?.apalyFee?.type == 'percent'">%</span> 
                                <span v-if="memberData.subscription?.apalyFee?.type == 'fix'">$</span> 
                            </p>
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="font" for="">TPA Processing Fee  </label>
                        </div>
                        <div class="col-12 col-md-6">
                            <p class="font-weight-bold">{{memberData.subscription?.tpaFee?.amount}} 
                                <span v-if="memberData.subscription?.tpaFee?.type == 'percent'">%</span> 
                                <span v-if="memberData.subscription?.tpaFee?.type == 'fix'">$</span> 
                            </p>
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="font" for="">Solution Facilitator Fee  </label>
                        </div>
                        <div class="col-12 col-md-6">
                            <p class="font-weight-bold">
                                
                                <span v-if="memberData.subscription?.facilitatorFee?.type == 'fix'">$ </span>
                                 {{memberData.subscription?.facilitatorFee?.amount.toFixed(2)}} 
                                 <span v-if="memberData.subscription?.facilitatorFee?.type == 'percent'">%</span> 
                            </p>
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="font" for="">Net Total Yearly </label>
                            <p class="font-weight-bold">${{memberData.subscription?.yearlyNet.toFixed(2)}} </p>

                            <!-- <v-text-field
                            disabled
                            hide-details
                            v-model.trim="subscription.subscriptionId"
                            solo
                            ></v-text-field> -->
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="font" for="">Net Total Monthly  </label>
                            <p class="font-weight-bold" >${{memberData.subscription?.monthlyNet.toFixed(2)}} </p>

                            <!-- <v-text-field
                            disabled
                            hide-details
                            v-model.trim="subscription.subscriptionId"
                            solo
                            ></v-text-field> -->
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="font" for="">Amount Paid   </label>
                            <p class="font-weight-bold"> ${{memberData.subscription?.paid.toFixed(2)}} </p>

                            <!-- <v-text-field
                            disabled
                            hide-details
                            v-model.trim="subscription.subscriptionId"
                            solo
                            ></v-text-field> -->
                        </div>
                        <div class="col-12 col-md-6">
                            <label class="font" for="">Balance</label>
                            <p class="font-weight-bold">${{memberData.subscription?.balance.toFixed(2)}} </p>

                            <!-- <v-text-field
                            disabled
                            hide-details
                            v-model.trim="subscription.subscriptionId"
                            solo
                            ></v-text-field> -->
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </v-form>
    <v-divider class="mt-10 mb-10"></v-divider>
    <div class="row">
        <div class="col-12 col-md-12">
            <h3>Payment History</h3>
        </div>
        <div class="col-12">
            <v-simple-table fixed-header width="100" >
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">Reference Number</th>
                    <th class="text-left">Amount</th>
                    <th class="text-left">Date</th>
                    <th class="text-left">Status</th>
                  </tr>
                  </thead>
                  <tbody
                      v-if="memberData.subscription.transactions.length > 0"
                      id="remove-hover"
                  >
                  <tr
                      v-for="(item, index) in memberData.subscription.transactions"
                      :key="index"
                      class="text-gray"
                  >
                    <td>{{item._id}}</td>
                    <td>$ {{item.debit.toFixed(2)}}</td>
                    <td>  {{ item.date | moment("MM/DD/YYYY") }} </td>
                    <td>
                        <v-chip class="ma-2" :color="item.status == 'paid' ? 'success':'red'">
                            {{item.status}}
                        </v-chip>
                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else-if=" memberData.subscription.transactions.length < 1">
                    <tr>
                        <td colspan="4">
                            <v-alert
                            border="bottom"
                            colored-border
                            type="warning"
                            elevation="2"
                            class="text-gray"
                        >
                          Sorry! No results found
                        </v-alert>
                        </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
        </div>

    </div>
  </div>
</template>
<script>
import api from "../../../../services/api";
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import removeDialog from "../../../TablesTemplate/RemoveDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

export default {
  components: { successModal, removeDialog, errorModal },
  props:{
    memberData:{
        required:true,
        type:Object
    }
  },
  data() {
    return {
      subscription: {},
      invoice: {},
      transactions: [],
    };
  },
  computed: {
    singleMember() {
      return this.$store.getters.getSingleProviderMember;
    },
  },
  created() {
    this.member = this.singleMember;
  },
  methods: {
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      this.$router.push("/dpc-providers/members/");
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
  },
};
</script>
