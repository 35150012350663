<template>
  <div class="row col-md-12 no-padding">
    <div class="col-md-12 padding-wizard">
      <div class="blue-underline row justify-space-between">
        <v-col class="col-xl-4 col-lg-4 col-md-2 pl-6" cols="12">
          <h3 class="font-quicksand-medium-18">
            NEW MEMBER REGISTRATION -
            <span>{{ pageAndTitle.title }}</span>
          </h3>
        </v-col>
        <v-col class="col-xl-2 col-lg-4 col-md-4 text-center" cols="3">
          <h3>{{ pageAndTitle.page }} <span class="text-gray">/3</span></h3>
        </v-col>
        <v-col class="col-xl-6 col-lg-4 col-md-4 text-end" cols="3">
          <i
            class="fa fa-times text-gray pr-5 pointer"
            style="font-size: 1.5rem"
            @click="$router.go(-1)"
          ></i>
        </v-col>
      </div>
      <div class="row" :class="showOverlay ? ' postion-relative' : ''">
        <!-- OVERLAY -->
        <div v-if="showOverlay" class="wizard-absolute">
          <div v-if="dialog" width="400" class="modal-wizard-check mt-4">
            <v-card class="col-md-12">
              <div
                class="row text-center justify-center orange-underline-wizard"
              >
                <div
                  class="
                    text-center
                    justify-center
                    col-md-9
                    font-weight-bold
                    mt-3
                    mb-3
                    pl-4
                  "
                >
                  <span class="ml-6">Choose and Action</span>
                </div>
                <div class="col-md-1 mt-3">
                  <span class="text-end ml-3"
                    ><i
                      class="fa fa-times text-gray pointer"
                      style="font-size: 22px"
                      @click="closeModal"
                    ></i>
                  </span>
                </div>
              </div>
              <div class="mt-10">
                <div class="justify-center row text-center padding-on-mobile">
                  <div
                    class="
                      blue-success-modal
                      mt-10
                      col-md-9
                      justify-center
                      pointer
                    "
                    @click="next('checkNowDialog')"
                  >
                    <svg
                      class="mt-2"
                      width="50"
                      height="51"
                      viewBox="0 0 50 51"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.2852 28.9142L21.6066 34.7714C21.8032 34.9333 22.0335 35.0493 22.2806 35.1111C22.5277 35.1729 22.7855 35.1788 23.0352 35.1285C23.2872 35.0812 23.5262 34.9802 23.7357 34.8323C23.9453 34.6844 24.1205 34.493 24.2494 34.2714L35.7137 14.6285"
                        stroke="#4072B7"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24.9994 48.5571C37.8203 48.5571 48.2137 38.1637 48.2137 25.3428C48.2137 12.5219 37.8203 2.12849 24.9994 2.12849C12.1785 2.12849 1.78516 12.5219 1.78516 25.3428C1.78516 38.1637 12.1785 48.5571 24.9994 48.5571Z"
                        stroke="#4072B7"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div class="success-modal-blue-text mt-2 mb-2">
                      Check In Now
                    </div>
                  </div>
                </div>
                <div
                  class="
                    col-md-12
                    justify-center
                    text-center
                    mt-10
                    mb-10
                    font-weight-bold
                  "
                >
                  OR
                </div>
                <div
                  class="
                    justify-center
                    row
                    mt-2
                    text-center
                    mb-15
                    padding-on-mobile
                  "
                >
                  <div
                    class="green-success-modal col-md-9 justify-center pointer"
                    @click="next('futureVisitDate');"
                  >
                    <svg
                      class="mt-2"
                      width="54"
                      height="54"
                      viewBox="0 0 54 54"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M49.8261 6.34784H4.17391C2.97329 6.34784 2 7.32113 2 8.52175V49.8261C2 51.0267 2.97329 52 4.17391 52H49.8261C51.0267 52 52 51.0267 52 49.8261V8.52175C52 7.32113 51.0267 6.34784 49.8261 6.34784Z"
                        stroke="#09951F"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.8672 2V12.8696"
                        stroke="#09951F"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M41.1328 2V12.8696"
                        stroke="#09951F"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2 17.2174H52"
                        stroke="#09951F"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M36.784 33.5218C36.784 33.2335 36.6694 32.957 36.4656 32.7532C36.2618 32.5493 35.9853 32.4348 35.697 32.4348H30.2622V27C30.2622 26.7117 30.1477 26.4353 29.9439 26.2314C29.74 26.0276 29.4636 25.9131 29.1753 25.9131H24.8274C24.5392 25.9131 24.2627 26.0276 24.0589 26.2314C23.855 26.4353 23.7405 26.7117 23.7405 27V32.4348H18.3057C18.0174 32.4348 17.741 32.5493 17.5371 32.7532C17.3333 32.957 17.2188 33.2335 17.2188 33.5218V37.8696C17.2188 38.1579 17.3333 38.4343 17.5371 38.6382C17.741 38.842 18.0174 38.9565 18.3057 38.9565H23.7405V44.3913C23.7405 44.6796 23.855 44.9561 24.0589 45.1599C24.2627 45.3638 24.5392 45.4783 24.8274 45.4783H29.1753C29.4636 45.4783 29.74 45.3638 29.9439 45.1599C30.1477 44.9561 30.2622 44.6796 30.2622 44.3913V38.9565H35.697C35.9853 38.9565 36.2618 38.842 36.4656 38.6382C36.6694 38.4343 36.784 38.1579 36.784 37.8696V33.5218Z"
                        stroke="#09951F"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div class="success-modal-green-text mt-3 mb-2">
                      Set Future Visit Date
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
            </v-card>
          </div>
          <div
            v-if="checkNowDialog"
            width="600"
            class="modal-wizard-check mt-4"
          >
            <v-card class="col-md-12">
              <div
                class="row text-center justify-center orange-underline-wizard"
              >
                <div
                  class="
                    text-center
                    justify-center
                    col-md-9
                    font-weight-bold
                    mt-3
                    mb-3
                  "
                >
                  Check Member In Now
                </div>
                <div class="col-md-1 mt-3">
                  <span class="text-end"
                    ><i
                      class="fa fa-times text-gray ml-3 pointer"
                      style="font-size: 22px"
                      @click="closeModal"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="row justify-center mt-10 mb-10 ">
                <div style="width: 90%">
                <v-label class="text-gray">First Name</v-label>
                <v-text-field
                    :disabled="storeMember.firstName ? true : false"
                    solo
                    v-model="member.firstName"
                ></v-text-field>
                </div>
                <div style="width: 90%">
                  <v-label class="text-gray">Last Name</v-label>
                  <v-text-field
                      :disabled="storeMember.lastName ? true : false"
                      solo
                      v-model="member.lastName"
                  ></v-text-field>
                </div>
                <div style="width: 90%">
                <v-label class="text-gray">Email</v-label>
                 <v-text-field
                    v-if="storeMember.email"
                    solo
                    @blur="checkEmailValidation"
                    :rules="emailRules"
                    v-model="storeMember.email"
                ></v-text-field>
                <v-text-field
                v-else
                    solo
                      @blur="checkEmailValidation"
                    :rules="emailRules"
                    v-model="member.email"
                ></v-text-field>
                </div>
                <div style="width: 90%">
                  <v-label class="text-gray">Phone</v-label>
                    <v-text-field
                      v-if="storeMember.phone"
                      solo
                      :rules="phoneRules"
                      v-model="storeMember.phone"
                  ></v-text-field>
                  <v-text-field
                      v-else
                      solo
                      :rules="phoneRules"
                      v-model="member.phone"
                  ></v-text-field>
                </div>




              </div>

              <div class="mt-10 justify-center row text-center mb-8">
                <div
                  class="blue-success-modal col-md-10 justify-center pointer"
                  @click="checkMemberNow"
                >
                  <svg
                    class="mt-3"
                    width="50"
                    height="51"
                    viewBox="0 0 50 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.2852 28.9142L21.6066 34.7714C21.8032 34.9333 22.0335 35.0493 22.2806 35.1111C22.5277 35.1729 22.7855 35.1788 23.0352 35.1285C23.2872 35.0812 23.5262 34.9802 23.7357 34.8323C23.9453 34.6844 24.1205 34.493 24.2494 34.2714L35.7137 14.6285"
                      stroke="#4072B7"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24.9994 48.5571C37.8203 48.5571 48.2137 38.1637 48.2137 25.3428C48.2137 12.5219 37.8203 2.12849 24.9994 2.12849C12.1785 2.12849 1.78516 12.5219 1.78516 25.3428C1.78516 38.1637 12.1785 48.5571 24.9994 48.5571Z"
                      stroke="#4072B7"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="success-modal-blue-text mb-3 mt-2"
                  style="font-size: 15px;">
                    Confirm / Enter Information and Check In
                  </div>
                </div>
              </div>
            </v-card>
          </div>
          <div
            v-if="futureVisitDate"
            width="600"
            class="modal-wizard-check mt-4"
          >
            <v-card class="col-md-12">
              <div
                class="row text-center justify-center orange-underline-wizard"
              >
                <div
                  class="
                    text-center
                    justify-center
                    col-md-9
                    font-weight-bold
                    mt-3
                    mb-3
                  "
                >
                  Set a future visit date
                </div>
                <div class="col-md-1 mt-3">
                  <span class="text-end"
                    ><i
                      class="fa fa-times text-gray pointer ml-3"
                      style="font-size: 22px"
                      @click="closeModal(); addMember()"
                    ></i>
                  </span>
                </div>
              </div>

              <div class="row justify-center mt-10">
                <v-card>
                  <v-date-picker
                    class="custom-date-picker"
                    v-model.trim="futureDate"
                    no-title
                    scrollable
                    :show-current="true"
                    :color="'#084ba7'"
                    show-adjacent-months
                    :weekday-format="parseDate"
                    next-icon="fa-solid fa-caret-right"
                    prev-icon="fa-solid fa-caret-left"
                  >
                  </v-date-picker>
                </v-card>
              </div>
              <div
                class="
                  justify-center
                  row
                  mt-2
                  text-center
                  mb-15
                  padding-on-mobile
                "
              >
                <div
                  class="
                    green-success-modal
                    col-md-9
                    mt-10
                    mb-2
                    justify-center
                    pointer
                  "
                  @click="setFutureDate"
                >
                  <svg
                    class="mt-2"
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M49.8261 6.34784H4.17391C2.97329 6.34784 2 7.32113 2 8.52175V49.8261C2 51.0267 2.97329 52 4.17391 52H49.8261C51.0267 52 52 51.0267 52 49.8261V8.52175C52 7.32113 51.0267 6.34784 49.8261 6.34784Z"
                      stroke="#09951F"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.8672 2V12.8696"
                      stroke="#09951F"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M41.1328 2V12.8696"
                      stroke="#09951F"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 17.2174H52"
                      stroke="#09951F"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M36.784 33.5218C36.784 33.2335 36.6694 32.957 36.4656 32.7532C36.2618 32.5493 35.9853 32.4348 35.697 32.4348H30.2622V27C30.2622 26.7117 30.1477 26.4353 29.9439 26.2314C29.74 26.0276 29.4636 25.9131 29.1753 25.9131H24.8274C24.5392 25.9131 24.2627 26.0276 24.0589 26.2314C23.855 26.4353 23.7405 26.7117 23.7405 27V32.4348H18.3057C18.0174 32.4348 17.741 32.5493 17.5371 32.7532C17.3333 32.957 17.2188 33.2335 17.2188 33.5218V37.8696C17.2188 38.1579 17.3333 38.4343 17.5371 38.6382C17.741 38.842 18.0174 38.9565 18.3057 38.9565H23.7405V44.3913C23.7405 44.6796 23.855 44.9561 24.0589 45.1599C24.2627 45.3638 24.5392 45.4783 24.8274 45.4783H29.1753C29.4636 45.4783 29.74 45.3638 29.9439 45.1599C30.1477 44.9561 30.2622 44.6796 30.2622 44.3913V38.9565H35.697C35.9853 38.9565 36.2618 38.842 36.4656 38.6382C36.6694 38.4343 36.784 38.1579 36.784 37.8696V33.5218Z"
                      stroke="#09951F"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div class="success-modal-green-text mt-3 mb-2">
                    Set Future Visit Date
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
        <div class="col-md-6">
          <h4 class="text-underline col-md-12">Member Information</h4>
          <div class="col-md-12 pb-0">

            <v-label class="text-gray">First Name</v-label>
            <v-text-field  v-if="storeMember.firstName"
              :disabled="storeMember.firstName ? true : false"
              solo
              v-model="storeMember.firstName"
            ></v-text-field>
            <v-text-field v-else
              :disabled="mem.firstName ? true : false"
              solo
              v-model="mem.firstName"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Middle Name</v-label>
            <v-text-field  v-if="storeMember.middleName"
              :disabled="storeMember.middleName ? true : false"
              solo
              v-model="storeMember.middleName"
            ></v-text-field>
            <v-text-field v-else
              :disabled="mem.middleName ? true : false"
              solo
              v-model="mem.middleName"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Last Name</v-label>
            <v-text-field  v-if="storeMember.lastName"
              :disabled="storeMember.lastName ? true : false"
              solo
              v-model="storeMember.lastName"
            ></v-text-field>
            <v-text-field v-else
              :disabled="mem.lastName ? true : false"
              solo
              v-model="mem.lastName"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Relationship to Subscriber</v-label>
            <v-text-field  v-if="storeMember.subscriberRelation"
              :disabled="storeMember.subscriberRelation ? true : false"
              solo
              v-model="storeMember.subscriberRelation"
            ></v-text-field>
            <v-text-field v-else
              :disabled="mem.subscriberRelation ? true : false"
              solo
              v-model="mem.subscriberRelation"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Phone</v-label>
            <v-text-field v-if="storeMember.phone"
              solo
              :rules="phoneRules"
              v-model="storeMember.phone"
            ></v-text-field>
            <v-text-field
            v-else
              solo
              :rules="phoneRules"
              v-model="mem.phone"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Email</v-label>
            <v-text-field v-if="storeMember.email"
              solo
              :rules="emailRules"
              v-model="storeMember.email"
            ></v-text-field>
            <v-text-field
                  v-else
              solo
              :rules="emailRules"
              v-model="mem.email"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Home Zip Code</v-label>
            <v-text-field v-if="storeMember.zip"
                solo 
               v-model="storeMember.zip">
              </v-text-field>
               <v-text-field v-else
               solo 
               v-model="mem.zip">
              </v-text-field>
          </div>
          <br /><br /><br /><br />
          <br /><br /><br /><br />
        </div>
        <div class="col-md-6">
          <h4 class="text-underline col-md-12">
            Employer and Plan Information
          </h4>
          <div class="col-md-12 pb-0">
            <v-label class="text-gray">Company Name </v-label>
            <v-text-field v-if="storeMember.employer && storeMember.employer.companyName"
              solo
                :disabled="storeMember.employer.companyName? true : false"
                v-model="storeMember.employer.companyName"
            ></v-text-field>
            
            <v-text-field v-else
              solo
              v-model="mem.companyName"
            ></v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Group ID</v-label>
            <v-text-field 
            solo  
             :disabled="storeMember.groupId ? true : false"
              v-model="storeMember.groupId">
            </v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Plan ID</v-label>
            <v-text-field solo   
            :disabled="storeMember.planId ? true : false"
             v-model="storeMember.planId">
            </v-text-field>
          </div>
          <div class="col-md-12 pt-0 pb-0">
            <v-label class="text-gray">Member ID</v-label>
            <v-text-field
              solo
                :disabled="storeMember.memberId ? true : false"
              v-model="storeMember.memberId"
            ></v-text-field>
          </div>
          <div class="col-md-12 row pt-0 pb-0">
            <span class="col-md-3 pb-1 text-gray"> Eligibility Status: </span>
            <span class="font-weight-bold col-md-9 pb-1"
              > Eligibility confirmed on {{ new Date().toLocaleString() | moment("MM/DD/YYYY")}}
            </span>
          </div>
          <div class="col-md-12 row pb-0">
            <span class="col-md-3 text-gray pb-1"> Deductible Balance:</span>

            <span class="font-weight-bold col-md-6 pb-1">{{
              storeMember.deductible
            }}</span>
          </div>
          <div class="col-md-12 row pb-0">
            <span class="col-md-3 pb-1 text-gray"> Next Visit Date:</span>
            <span class="font-weight-bold col-md-3 pb-1"
             v-if="storeMember.nextVisit">
             {{  storeMember.nextVisit | moment("dddd, MMMM, YYYY") }}</span>
                <span class="font-weight-bold col-md-3 pb-1" v-else>
             Not Set
            </span>
          </div>

          <div class="col-md-12 row pb-0">
            <span class="col-md-3 text-gray">Subscription:</span>

            <span class="font-weight-bold col-md-6" v-if="storeMember.id">
              {{ storeMember.subscription.status.charAt(0).toUpperCase() + storeMember.subscription.status.slice(1) }}
            </span>
          </div>
          <br /><br /><br /><br />
          <br /><br /><br /><br />
        </div>

        <!-- Modals -->

        <v-dialog
          v-model="paymentDialog"
          hide-overlay
          width="400"
          content-class="modal-wizard-check"
        >
          <v-card class="col-md-12">
            <div class="row text-center justify-center orange-underline-wizard">
              <div
                class="
                  text-center
                  justify-center
                  col-md-9
                  font-weight-bold
                  mt-3
                  mb-3
                "
              >
                Member Payment Required
              </div>
              <div class="col-md-1 mt-3 mr-3">
                <span class="text-end"
                  ><i
                    class="fa fa-times text-gray pointer"
                    style="font-size: 22px"
                    @click="closeModal"
                  ></i>
                </span>
              </div>
            </div>

            <div class="row col-md-12 justify-center">
              <div
                class="font-weight-bold col-md-11 ml-4"
                style="font-size: 14px"
              >
                The member’s plan requires payment from the member in the amount
                of:
                <span class="font-weight-bolder" style="font-size: 18px"
                  >$60,00</span
                >
              </div>
            </div>
            <div
              class="
                mt-6
                justify-center
                row
                orange-underline-top-wizard
                text-center
              "
            >
              <div
                class="blue-success-modal mt-5 col-md-9 justify-center pointer"
                @click="next('proccessPaymentDialog')"
              >
                <svg
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M48.1538 25.0769H5.84615C3.72198 25.0769 2 26.7989 2 28.9231V48.1538C2 50.278 3.72198 52 5.84615 52H48.1538C50.278 52 52 50.278 52 48.1538V28.9231C52 26.7989 50.278 25.0769 48.1538 25.0769Z"
                    stroke="#084BA7"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.5391 7.76926V15.4616"
                    stroke="#084BA7"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27 2V15.4615"
                    stroke="#084BA7"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M40.4609 7.76926V15.4616"
                    stroke="#084BA7"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27.0036 44.3077C30.1899 44.3077 32.7728 41.7247 32.7728 38.5385C32.7728 35.3522 30.1899 32.7693 27.0036 32.7693C23.8173 32.7693 21.2344 35.3522 21.2344 38.5385C21.2344 41.7247 23.8173 44.3077 27.0036 44.3077Z"
                    stroke="#084BA7"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div class="success-modal-blue-text mt-4 mb-2">
                  Process Member Payment Now
                </div>
              </div>
            </div>
            <div
              class="col-md-12 justify-center text-center mt-3 font-weight-bold"
            >
              OR
            </div>
            <div
              class="justify-center row mt-1 text-center mb-6 pointer"
              @click="next('sendPaymentLinkDialog')"
            >
              <div class="green-success-modal col-md-9 justify-center">
                <svg
                  class="mt-2"
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.81467 35.0684C4.98637 35.0684 4.18058 34.8071 3.51906 34.3239C2.85754 33.8408 2.37623 33.1621 2.14786 32.3904C1.91949 31.6187 1.95646 30.7959 2.2532 30.0464C2.54994 29.2969 3.09032 28.6613 3.79268 28.2357L46.1651 2.56281C46.7937 2.18154 47.5233 1.98621 48.2647 2.00076C49.0061 2.0153 49.727 2.23908 50.3391 2.64469C50.9513 3.0503 51.4281 3.62013 51.7112 4.2844C51.9943 4.94867 52.0714 5.67855 51.933 6.38464L43.68 48.987C43.5431 49.6918 43.1976 50.3428 42.6856 50.8606C42.1737 51.3785 41.5174 51.7408 40.7968 51.9036C40.0761 52.0663 39.3222 52.0224 38.6268 51.7771C37.9314 51.5319 37.3247 51.0959 36.8804 50.5224L24.9365 35.0684H5.81467Z"
                    stroke="#09951F"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.9383 35.0684H19.207V46.5583C19.207 47.2305 19.3961 47.8899 19.754 48.4656C20.1119 49.0413 20.625 49.5115 21.2382 49.8256C21.8513 50.1396 22.5412 50.2857 23.2336 50.2479C23.926 50.2102 24.5946 49.9901 25.1676 49.6114L32.4531 44.7985L24.9383 35.0684Z"
                    stroke="#09951F"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M24.9336 35.0684L50.3346 2.64948"
                    stroke="#09951F"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div class="success-modal-green-text mt-4 mb-2">
                  Send Payment Link to Member
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="proccessPaymentDialog"
          hide-overlay
          width="400"
          content-class="modal-wizard-check"
        >
          <v-card class="col-md-12">
            <div class="row text-center justify-center orange-underline-wizard">
              <div
                class="
                  text-center
                  justify-center
                  col-md-9
                  font-weight-bold
                  mt-3
                  mb-3
                "
              >
                Process member payment now
              </div>
              <div class="col-md-1 mt-3 mr-3">
                <span class="text-end"
                  ><i
                    class="fa fa-times text-gray pointer"
                    style="font-size: 22px"
                    @click="closeModal"
                  ></i>
                </span>
              </div>
            </div>

            <div class="row col-md-12 ml-1" style="padding: 15px">
              <div class="col-md-12 no-padding">
                <v-label>First Name on Card </v-label>
                <v-text-field solo hide-details></v-text-field>
              </div>
              <div class="col-md-12 no-padding">
                <v-label>Last Name on Card</v-label>
                <v-text-field solo hide-details></v-text-field>
              </div>
              <div class="col-md-12 no-padding mt-0 mb-0">
                <v-label>Card Number</v-label>
                <v-text-field hide-details solo></v-text-field>
              </div>
              <div class="row no-padding mt-0 mb-0">
                <div class="col-md-6 mt-0 pl-0">
                  <v-label>Exp Date</v-label>
                  <v-text-field hide-details type="date" solo></v-text-field>
                </div>
                <div class="col-md-6 mt-0 pr-0">
                  <v-label>CCV</v-label>
                  <v-text-field solo hide-details></v-text-field>
                </div>
                <div class="col-md-6 mt-0 pl-0">
                  <v-label>Zip Code</v-label>
                  <v-text-field solo hide-details></v-text-field>
                </div>
                <div class="col-md-6 mt-0 pr-0">
                  <v-label>Amount</v-label>
                  <v-text-field solo hide-details></v-text-field>
                </div>
              </div>
            </div>

            <div
              class="mt-3 mb-5 justify-center row pointer text-center"
              @click="next('sendPaymentLinkDialog')"
            >
              <div class="blue-success-modal mt-5 col-md-10 justify-center">
                <svg
                  class="mt-3"
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M48.1538 25.0769H5.84615C3.72198 25.0769 2 26.7989 2 28.9231V48.1538C2 50.278 3.72198 52 5.84615 52H48.1538C50.278 52 52 50.278 52 48.1538V28.9231C52 26.7989 50.278 25.0769 48.1538 25.0769Z"
                    stroke="#084BA7"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.5391 7.76926V15.4616"
                    stroke="#084BA7"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27 2V15.4615"
                    stroke="#084BA7"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M40.4609 7.76926V15.4616"
                    stroke="#084BA7"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M27.0036 44.3077C30.1899 44.3077 32.7728 41.7247 32.7728 38.5385C32.7728 35.3522 30.1899 32.7693 27.0036 32.7693C23.8173 32.7693 21.2344 35.3522 21.2344 38.5385C21.2344 41.7247 23.8173 44.3077 27.0036 44.3077Z"
                    stroke="#084BA7"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div class="success-modal-blue-text mt-4 mb-4">
                  Process Member Payment Now
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="sendPaymentLinkDialog"
          hide-overlay
          width="400"
          content-class="modal-wizard-check"
        >
          <v-card class="col-md-12">
            <div class="row text-center justify-center orange-underline-wizard">
              <div
                class="
                  text-center
                  justify-center
                  col-md-9
                  font-weight-bold
                  mt-3
                  mb-3
                "
              >
                Send payment link to member
              </div>
              <div class="col-md-1 mt-3 mr-3">
                <span class="text-end"
                  ><i
                    class="fa fa-times text-gray pointer"
                    style="font-size: 22px"
                    @click="closeModal"
                  ></i>
                </span>
              </div>
            </div>
            <div class="col-md-12">
              <div
                class="row col-md-12 justify-center font-weight-bold"
                style="font-size: 14px"
              >
                <span class="mt-4 ml-2">
                  Confirm member’s email and phone to send payment link via
                  email and/or text.
                </span>
              </div>
              <div class="row col-md-12 justify-center pl-4 pl-4">
                <div class="col-md-12 mb-5 no-padding">
                  <v-label> Member Email</v-label>
                  <v-text-field solo></v-text-field>
                </div>
                <div class="col-md-12 no-padding">
                  <v-label> Member Phone</v-label>
                  <v-text-field solo></v-text-field>
                </div>
              </div>

              <div
                class="
                  justify-center
                  row
                  mt-1
                  text-center
                  mb-6
                  pointer
                  col-md-12
                "
                @click="closeModal"
              >
                <div class="green-success-modal col-md-12 ml-1">
                  <svg
                    class="mt-2"
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.81467 35.0684C4.98637 35.0684 4.18058 34.8071 3.51906 34.3239C2.85754 33.8408 2.37623 33.1621 2.14786 32.3904C1.91949 31.6187 1.95646 30.7959 2.2532 30.0464C2.54994 29.2969 3.09032 28.6613 3.79268 28.2357L46.1651 2.56281C46.7937 2.18154 47.5233 1.98621 48.2647 2.00076C49.0061 2.0153 49.727 2.23908 50.3391 2.64469C50.9513 3.0503 51.4281 3.62013 51.7112 4.2844C51.9943 4.94867 52.0714 5.67855 51.933 6.38464L43.68 48.987C43.5431 49.6918 43.1976 50.3428 42.6856 50.8606C42.1737 51.3785 41.5174 51.7408 40.7968 51.9036C40.0761 52.0663 39.3222 52.0224 38.6268 51.7771C37.9314 51.5319 37.3247 51.0959 36.8804 50.5224L24.9365 35.0684H5.81467Z"
                      stroke="#09951F"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24.9383 35.0684H19.207V46.5583C19.207 47.2305 19.3961 47.8899 19.754 48.4656C20.1119 49.0413 20.625 49.5115 21.2382 49.8256C21.8513 50.1396 22.5412 50.2857 23.2336 50.2479C23.926 50.2102 24.5946 49.9901 25.1676 49.6114L32.4531 44.7985L24.9383 35.0684Z"
                      stroke="#09951F"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24.9336 35.0684L50.3346 2.64948"
                      stroke="#09951F"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <div class="success-modal-green-text mt-4 mb-2">
                    Send Payment Link to Member
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>

      <div class="row mt-3 gray-underline-wizard">
        <div class="col-md-12 row ml-1">
          <div
            v-if="pageAndTitle.page != '1'"
            @click="backButton"
            style="color: #0572F3;"
            class="col-md-2 text-underline pointer mt-2 mb-2"
          >
            Back
          </div>
          <div
            :class="pageAndTitle.page != '1' ? 'col-md-10' : 'col-md-12'"
            class="text-end justify-end mt-2 mb-4"
          >
            <span
              v-if="saveButton == true  || pageAndTitle.page == '3'"
              style="color:#0572F3;"
              class="text-underline pointer"
              :class="pageAndTitle.page != '3' ? 'mr-15' : ''"
              @click="submit"
              >Save</span
            >
            <span
             v-if="pageAndTitle.page != '3' && nextButton == true"
              class="pointer mr-5"
              style="color:#0572F3;"
              @click="nextModal()"
            >
              <span class="text-underline">Next</span>

            </span>
          </div>
        </div>
      </div>
    </div>
    <errorModal ref="error_modal" v-on:confirm="closeErrorModal"></errorModal>

    <successModal
      ref="success_modal"
      v-on:confirm="closeSuccessModal"
    ></successModal>
  </div>
</template>
<script>
import successModal from "../../../TablesTemplate/SuccessDialog.vue";
import errorModal from "../../../TablesTemplate/ErrorDialog.vue";

import api from "../../../../services/api";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: { successModal, errorModal },
  data() {
    return {
      saveButton: false,
      nextButton: true,
      wizardData: {
        steps: 1,
      },
      mem: {
        payerId: "",
        groupId: "",
        planId: "",
        memberId: "",
        firstName: "",
        middleName: "",
        lastName: "",
        phone: "",
        email: "",
        zip: 36606,
        companyName: "",
        deductible: 1200,
        subscriberRelation: "Self",
        subscriberItems: ["Self", "Spouse", "Dependent"],
      },
      members:[],
      dialog: false,
      overlay: true,
      zIndex: 999,
      absolute: true,
      drawer: false,
      sickVisit: false,
      paymentDialog: false,
      proccessPaymentDialog: false,
      sendPaymentLinkDialog: false,
      checkNowDialog: false,
      showSave: true,
      memberId: "",
      futureVisitDate: false,
      checkNowDate: "",
      futureDate: "",
      date: "",
      responseId: "",
      showOverlay: false,
      saveMember: false,
      storeMember : null,
      emailValidated:true,
      taxIdRules: [
        (v) => !!v || "This Field is required",
        (v) =>
          !!/[0-9]{2}\-[0-9]{7}/.test(v) ||
          "Tax ID should contain 9 digits, Please enter your Tax ID in XX-XXXXXXX format",
      ],
      emailRules: [
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) =>
          /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
          "Phone number must be in a valid format (XXX XXX XXXX)",
      ],
      prevIcon: (
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.8615 16.6881L6.49533 16.7835C6.35384 16.7846 6.21363 16.7461 6.08408 16.6704C5.95453 16.5948 5.83864 16.4838 5.74416 16.3448L1.26431 9.83636C1.09651 9.59832 1.00176 9.28741 0.998466 8.96403C0.995173 8.64064 1.08357 8.32787 1.24649 8.08646L5.59276 1.47935C5.68352 1.33718 5.79695 1.22291 5.92513 1.14452C6.0533 1.06614 6.19313 1.02552 6.33485 1.02552L15.6677 0.930473C15.9329 0.927772 16.1886 1.06346 16.3787 1.30768C16.5687 1.55189 16.6775 1.88464 16.681 2.23272L16.8148 15.3658C16.8184 15.7064 16.7209 16.0348 16.5431 16.2816C16.3652 16.5283 16.1208 16.6741 15.8615 16.6881V16.6881Z"
            fill="#707070"
          />
        </svg>
      ),
      nextIcon: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.966667 0H10.3333C10.4748 0.000294794 10.6146 0.0402603 10.7434 0.117221C10.8722 0.194182 10.9869 0.306362 11.08 0.44625L15.4933 7C15.6587 7.23975 15.7503 7.5516 15.7503 7.875C15.7503 8.1984 15.6587 8.51025 15.4933 8.75L11.08 15.3125C10.9878 15.4537 10.8732 15.5668 10.7442 15.6439C10.6153 15.721 10.475 15.7602 10.3333 15.7588H1C0.734784 15.7588 0.48043 15.6205 0.292893 15.3743C0.105357 15.1282 1.54609e-07 14.7943 1.54609e-07 14.4463V1.3125C-0.000144074 0.971853 0.100625 0.644476 0.281012 0.399554C0.461398 0.154633 0.70727 0.0113549 0.966667 0V0Z"
            fill="#707070"
          />
        </svg>
      ),
    };
  },

  computed: {
    ...mapGetters(["getSingleMember", "getLocationId", "getEligibilityMember", "getSingleProviderMember"]),
     singleMember() {
      return this.$store.getters.getSingleProviderMember;
    },
    pageAndTitle() {
      let titAndPage = {
        title: "CONFIRM INFORMATION",
        page: 1,
      };
      if (this.dialog) {
        titAndPage.title = "CHOOSE VISIT TIMING";
        titAndPage.page = 2;
      } else if (this.checkNowDialog) {
        titAndPage.title = "CHECK IN";
        titAndPage.page = 3;
      } else if (this.futureVisitDate) {
        titAndPage.title = "SET FUTURE VISIT DATE";
        titAndPage.page = 3;
      }
      return titAndPage;
    },
  },
  created() {
    // console.log('id',this.getSingleMember._id)
    if( this.getSingleMember._id == null || this.getSingleMember._id == undefined){
      this.saveButton = true;
      this.nextButton = false;
    }
      api()
          .get("/dpcproviders/members")
          .then((res) => {
            this.members = res.data;
            });
     this.storeMember = null;
    this.member = this.getSingleMember;
    console.log('this.member in created()',this.member)
    this.member.subscriberRelation = this.getSingleMember.subscriberRelation
        ? this.getSingleMember.subscriberRelation
        : "Self";
    this.memberId = this.getSingleMember.id;
    let store = JSON.stringify(this.getSingleMember)
    this.storeMember = JSON.parse(store)
    if (this.memberId) {
      this.showSave = false;
    } else {
      this.showSave = true;
    }
  },
  methods: {
    checkEmailValidation(){
      let data = {
        email: this.storeMember.email ? this.storeMember.email : this.member.email,
      }
       api()
          .post(`/auth/emailExists`, data)
          .then((res) => {
            console.log('rwer',res.data.exists);
            if(res.data.exists == true){
              this.$refs.error_modal.openPopUp("Email already exists! Please change your email.");
              console.log('okkk',res);
this.emailValdiated = false
            }else{
this.emailValdiated = true

            }
              
          });
      console.log('validate');
    },
    addMember(){
      this.member = this.getSingleMember;
      console.log('this.member in addMember()',this.member)
      this.$store.commit("storeMemberInfo", this.member);
      api()
          .put(`/dpcproviders/eligibility`, this.member)
          .then((res) => {
              console.log(res);
          });
    },
    closeErrorModal() {
      this.$refs.error_modal.closePopUp();
    },
    checkMemberNow() {
      if(this.emailValidated == false){
              this.$refs.error_modal.openPopUp("Email already exists! Please change your email.");

      }else{
        let data = {
        firstName: this.storeMember.firstName,
        lastName: this.storeMember.lastName,
        memberId: this.storeMember.memberId,
        payerId: this.storeMember.payerId,
        groupId: this.storeMember.groupId,
        planId: this.storeMember.planId,
        dob: this.storeMember.dob,
        zip: this.storeMember.zip,
        companyName: this.storeMember.employer ? this.storeMember.employer.companyName : this.mem.companyName,
        email: this.storeMember.email ? this.storeMember.email : this.mem.email,
        phone: this.storeMember.phone ? this.storeMember.phone : this.mem.phone,
        location: this.getLocationId ?  this.getLocationId : this.storeMember.location,
      
      }
      
       let data1 = {
        firstName:this.member.firstName,
        lastName:this.member.lastName,
        memberId:this.member.memberId,
        planId:this.member.planId,
        payerId:this.member.payerId,
        groupId:this.member.groupId,
        dob:this.member.dob,
        gender:this.member.gender,
        email:this.member.email,
        phone:this.member.phone,
        zip:this.member.zip,
        subscriberRelation: this.member.subscriberRelation,
        companyName: this.member.employer ? this.member.employer.companyName : this.mem.companyName ,
        verificationStatus:this.member.status,
        individualDeductible:this.member.deductible,
        traceNumber:this.member.traceNumber,
        eligibilityId:this.member.eligibilityId,
        _id: this.member._id
      }
      this.dialog = false;
      if (!this.saveMember) {
        if(this.member._id == null){
          api()
          .post(`/dpcproviders/eligibility/`, data1)
          .then((response) => {
            if (response) {
              api()
                .post(`/dpcproviders/checkin/` + response.data._id, data)
                .catch((err) => {
                  if (err) {
                    this.$refs.error_modal.openPopUp(
                      err.response.data
                    );
                  }
                })
                .then((res) => {
                  if (res) {
                    this.next("closeModal");
                    this.showOverlay = false;
                    this.$refs.success_modal.openPopUp(
                      "Member checked in successfully"
                    );
                  }
                });
            }
          })
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(
                err.response.data
              );
            }
          });
      
        }
        else{
          api()
          .put(`/dpcproviders/eligibility/`+this.member._id, data1)
          .then((response) => {
            console.log('resssssp', this.member)
            if (response) {
              api()
                .post(`/dpcproviders/checkin/` + response.data._id, data)
                .catch((err) => {
                  if (err) {
                    this.$refs.error_modal.openPopUp(
                      err.response.data
                    );
                  }
                })
                .then((res) => {
                  if (res) {
                    this.next("closeModal");
                    this.showOverlay = false;
                    this.$refs.success_modal.openPopUp(
                      "Member checked in successfully"
                    );
                  }
                });
            }
          })
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(
                err.response.data
              );
            }
          });
      
        }
     } else {
        api()
          .post(`/dpcproviders/checkin/` + this.responseId, data)
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(
                err.response.data
              );
            }
          })
          .then((res) => {
                     
            if (res) {
              this.next("closeModal");
              this.showOverlay = false;
              this.$refs.success_modal.openPopUp("Member updated successfully");
            }
          });
      }
      this.saveMember = false;
      }

      
      
    },

    setFutureDate() {
      let data = {
        date: this.futureDate,
      };
      if (!this.saveMember) {

        if(this.member._id == null){
          api()
          .post(`/dpcproviders/eligibility/`, this.member)
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(
                err.response.data
              );
            }
          })
          .then((response) => {
            if (response) {
              api()
                .post(`/dpcproviders/checkfuture/` + response.data._id, data)
                .catch((err) => {
                  if (err) {
                    this.$refs.error_modal.openPopUp(
                      err.response.data
                    );
                  }
                })
                .then((res) => {
                  if (res) {
                    this.next("closeModal");
                    this.showOverlay = false;
                    console.log(this.member)
                    this.$store.commit("storeMemberInfo", this.member);
                    this.addMember();
                    this.$refs.success_modal.openPopUp(
                      "Member created successfully"
                    );
                  }
                });
            }
          })
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(
                err.response.data
              );
            }
          });
        }
        else{
          api()
          .put(`/dpcproviders/eligibility/`+this.member._id, this.member)
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(
                err.response.data
              );
            }
          })
          .then((response) => {
            if (response) {
              api()
                .post(`/dpcproviders/checkfuture/` + response.data._id, data)
                .catch((err) => {
                  if (err) {
                    this.$refs.error_modal.openPopUp(
                      err.response.data
                    );
                  }
                })
                .then((res) => {
                  if (res) {
                    this.next("closeModal");
                    this.showOverlay = false;
                    console.log(this.member)
                    this.$store.commit("storeMemberInfo", this.member);
                    this.addMember();
                    this.$refs.success_modal.openPopUp(
                      "Member created successfully"
                    );
                  }
                });
            }
          })
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(
                err.response.data
              );
            }
          });
        }
      } else {
        api()
          .post(`/dpcproviders/checkfuture/` + this.responseId, data)
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(
                err.response.data
              );
            }
          })
          .then((res) => {
            if (res) {
              this.next("closeModal");
              this.showOverlay = false;
              this.$refs.success_modal.openPopUp("Member updated successfully");
            }
          });
      }
      this.saveMember = false;
    },
    closeSuccessModal() {
      this.$refs.success_modal.closePopUp();
      if (!this.saveMember) {
        this.$router.push("/dpc-providers/members");
      } else {
        this.nextModal();
      }
    },
    submit() {
      if (this.getLocationId) {
        api()
          .post(
            "/dpcproviders/eligibility?location=" + this.getLocationId,
            this.member
          )
          .catch((err) => {
            if (err) {
              this.$refs.error_modal.openPopUp(
                err.response.data.errors.email.message
              );
            }
          })
          .then((res) => {
          
            if (res) {
              this.responseId = res.data._id;
              this.saveMember = true;
              this.showSave = false;
            //   if( this.getSingleMember._id == null || this.getSingleMember._id == undefined){
            //     this.$refs.success_modal.openPopUp("Member created successfully");
            //     this.$router.push("/dpc-providers/members");
            //  }
              this.$refs.success_modal.openPopUp("Member created successfully");
            }
          });
      } else {
        this.$refs.error_modal.openPopUp("Please Select Location First!");
      }
    },
    parseDate(date) {
      const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    backButton() {
      if (this.dialog) {
        this.dialog = false;
        this.page = 1;
        this.showOverlay = false;
      } else if (this.checkNowDialog || this.futureVisitDate) {
        this.dialog = true;
        this.page = 2;
        this.checkNowDialog = false;
        this.futureVisitDate = false;
        this.showOverlay = true;
      } else {
        this.$router.go(-1);
      }
    },
    nextModal() {
      if (this.dialog) {
        this.dialog = false;
        this.checkNowDialog = true;
        this.wizardData.steps = 3;
        this.showOverlay = true;
      } else if (this.checkNowDialog || this.futureVisitDate) {
        this.dialog = false;
        this.checkNowDialog = false;
        this.futureVisitDate = false;
        this.pageAndTitle.page = 1;
        this.showOverlay = false;
      } else {
        this.wizardData.steps = 2;
        this.dialog = true;
        this.showOverlay = true;
      }
    },
    next(modal) {
      this.wizardData.steps++;
      this.paymentDialog = false;
      this.proccessPaymentDialog = false;
      this.sendPaymentLinkDialog = false;
      this.checkNowDialog = false;
      this.dialog = false;
      this.showOverlay = true;
      this[modal] = true;
    },
    closeModal() {
      this.wizardData.steps = 1;
      this.showOverlay = false;
      this.paymentDialog = false;
      this.proccessPaymentDialog = false;
      this.sendPaymentLinkDialog = false;
      this.checkNowDialog = false;
      this.futureVisitDate = false;
      this.dialog = false;
    },
  },
};
</script>
<style>
.custom-date-picker > .v-picker__body > div > .v-date-picker-header {
  border-bottom: 1px solid rgb(238, 238, 238);
}
.v-date-picker-header__value div button {
  font-weight: normal !important;
}
.v-picker__body {
  width: 320px;
}
.v-date-picker-table {
  height: 300px !important;
}
.v-date-picker-table table tbody tr {
  height: 40px;
  margin-bottom: 5px !important;
}
.v-date-picker-table table tbody tr td button .v-btn__content {
  font-size: 15px;
  margin-bottom: 5px;
}
</style>
