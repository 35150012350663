<template>
  <div :class="windowWidth ? 'mainSideBarDiv' : 'mainSideBarMobile'">
    <v-navigation-drawer v-if="windowWidth" :width="!mini ? '800' : '69'" permanent class="white-sidebar">
      <div>
        <v-divider></v-divider>

        <v-list-item v-if="user.role == 'admin' || user.role == 'moderator' || user.role == 'user'" two-line class="mt-3"
          :class="watchActiveItem == 'home' ? 'pointer active' : 'pointer inactive'
            " @click="activeItem('home')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-4 mt-lg-4 mt-md-2'
              : 'ml-4 mt-xl-4 mt-lg-3 mt-md-2'
            ">
            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.7319 6.49L9.00356 0.659668L1.26809 6.49C1.10817 6.63755 1.01201 6.84157 1 7.05881V15.9622C1.00056 16.1464 1.07402 16.323 1.20432 16.4532C1.33462 16.5835 1.51119 16.657 1.69547 16.6575H6.33333V12.9268C6.33333 12.2197 6.61429 11.5415 7.11438 11.0415C7.61448 10.5414 8.29276 10.2605 9 10.2605C9.70724 10.2605 10.3855 10.5414 10.8856 11.0415C11.3857 11.5415 11.6667 12.2197 11.6667 12.9268V16.6597H16.3038C16.4882 16.6591 16.6648 16.5857 16.7952 16.4554C16.9256 16.3252 16.9993 16.1486 17 15.9643V7.05881C16.9877 6.84165 16.8916 6.63773 16.7319 6.49Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
                mt-1
              ">
              Home
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :disabled="!activeEntity() || (user.entity && !user.entity.marketplaceVisibility && !user.superAdmin)" v-if="user.role == 'admin' || user.role == 'moderator'" two-line :class="watchActiveItem == 'solutions'
            ? 'pointer active'
            : 'pointer inactive'
          " @click="activeItem('solutions')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-4 mt-lg-4 mt-md-2'
              : 'ml-4 mt-xl-4 mt-lg-3 mt-md-2'
            ">
            <svg width="17" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path 
                d="M23.8388 9.00414L20.4113 3.00414C20.1975 2.63289 19.8 2.40039 19.3688 2.40039H4.63125C4.2 2.40039 3.8025 2.63289 3.58875 3.00414L0.161248 9.00414C-0.296252 9.80664 0.277498 10.8004 1.2 10.8004H2.4V20.3441C2.4 21.0379 2.93625 21.6004 3.6 21.6004H13.2C13.8638 21.6004 14.4 21.0379 14.4 20.3441V10.8004H20.4V21.3004C20.4 21.4654 20.535 21.6004 20.7 21.6004H21.3C21.465 21.6004 21.6 21.4654 21.6 21.3004V10.8004H22.8C23.7225 10.8004 24.2963 9.80664 23.8388 9.00414ZM13.2 20.3441C13.2 20.3779 13.1888 20.3966 13.1925 20.4004L3.6225 20.4079C3.6225 20.4079 3.6 20.3891 3.6 20.3441V15.6004H13.2V20.3441ZM13.2 14.4004H3.6V10.8004H13.2V14.4004ZM1.20375 9.60039L4.63125 3.60039H19.3688L22.8 9.60039H1.20375Z"
                fill="#707070" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
                mt-1
              ">
              Marketplace
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :disabled="!activeEntity()" v-if="user.role == 'admin' || user.role == 'moderator'" two-line :class="watchActiveItem == 'clients' ? 'pointer active' : 'pointer inactive'
          " @click="activeItem('clients')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2'
              : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            ">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.99991 12.7334H1.99998C1.73477 12.7334 1.48042 12.8458 1.29289 13.0458C1.10535 13.2459 1 13.5172 1 13.8001V17.0001H5.99991V12.7334Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M14.995 12.7334H10.9951V17.0001H15.995V13.8001C15.995 13.5172 15.8897 13.2459 15.7021 13.0458C15.5146 12.8458 15.2603 12.7334 14.995 12.7334Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M9.99993 8.4668H6.99998C6.73477 8.4668 6.48042 8.57918 6.29289 8.77922C6.10535 8.97925 6 9.25057 6 9.53346V17.0001H10.9999V9.53346C10.9999 9.25057 10.8946 8.97925 10.707 8.77922C10.5195 8.57918 10.2651 8.4668 9.99993 8.4668Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M6.74707 2.86667C6.74707 3.36174 6.93144 3.83653 7.25962 4.1866C7.58781 4.53667 8.03292 4.73333 8.49704 4.73333C8.96116 4.73333 9.40627 4.53667 9.73445 4.1866C10.0626 3.83653 10.247 3.36174 10.247 2.86667C10.247 2.3716 10.0626 1.8968 9.73445 1.54673C9.40627 1.19667 8.96116 1 8.49704 1C8.03292 1 7.58781 1.19667 7.25962 1.54673C6.93144 1.8968 6.74707 2.3716 6.74707 2.86667V2.86667Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M10.6653 6.333C10.3981 5.99838 10.066 5.72964 9.69193 5.54536C9.31785 5.36107 8.91071 5.26563 8.49866 5.26562C8.08661 5.26563 7.67947 5.36107 7.30539 5.54536C6.93131 5.72964 6.5992 5.99838 6.33203 6.333"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M12.0791 7.66647C12.0791 8.16154 12.2635 8.63634 12.5917 8.9864C12.9198 9.33647 13.3649 9.53314 13.8291 9.53314C14.2932 9.53314 14.7383 9.33647 15.0665 8.9864C15.3947 8.63634 15.579 8.16154 15.579 7.66647C15.579 7.1714 15.3947 6.69661 15.0665 6.34654C14.7383 5.99647 14.2932 5.7998 13.8291 5.7998C13.3649 5.7998 12.9198 5.99647 12.5917 6.34654C12.2635 6.69661 12.0791 7.1714 12.0791 7.66647V7.66647Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M15.9999 11.1331C15.6401 10.6818 15.1642 10.353 14.631 10.1873C14.0978 10.0216 13.5307 10.0263 13 10.2008"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M1.41504 7.66647C1.41504 7.91161 1.4603 8.15434 1.54825 8.38081C1.63619 8.60729 1.76509 8.81307 1.92759 8.9864C2.09009 9.15974 2.28301 9.29724 2.49532 9.39105C2.70764 9.48486 2.9352 9.53314 3.16501 9.53314C3.39482 9.53314 3.62237 9.48486 3.83469 9.39105C4.04701 9.29724 4.23992 9.15974 4.40242 8.9864C4.56492 8.81307 4.69382 8.60729 4.78177 8.38081C4.86971 8.15434 4.91497 7.91161 4.91497 7.66647C4.91497 7.42134 4.86971 7.1786 4.78177 6.95213C4.69382 6.72565 4.56492 6.51987 4.40242 6.34654C4.23992 6.1732 4.04701 6.03571 3.83469 5.9419C3.62237 5.84809 3.39482 5.7998 3.16501 5.7998C2.9352 5.7998 2.70764 5.84809 2.49532 5.9419C2.28301 6.03571 2.09009 6.1732 1.92759 6.34654C1.76509 6.51987 1.63619 6.72565 1.54825 6.95213C1.4603 7.1786 1.41504 7.42134 1.41504 7.66647V7.66647Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M1 11.1331C1.26773 10.7992 1.59996 10.531 1.97392 10.3469C2.34789 10.1628 2.75475 10.0671 3.16663 10.0664C3.44799 10.0666 3.72774 10.1117 3.99661 10.2001"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
                mt-1
              ">
              Clients
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :disabled="!activeEntity()" v-if="user.role == 'admin' || user.role == 'moderator' || user.role == 'user'" two-line :class="watchActiveItem == 'members' ? 'pointer active' : 'pointer inactive'" @click="activeItem('members')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2 '
              : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            ">
            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.2002 3.46152C12.2002 4.11436 12.425 4.74046 12.825 5.20208C13.2251 5.66371 13.7677 5.92305 14.3335 5.92305C14.8993 5.92305 15.4419 5.66371 15.842 5.20208C16.2421 4.74046 16.4669 4.11436 16.4669 3.46152C16.4669 2.80869 16.2421 2.18259 15.842 1.72096C15.4419 1.25934 14.8993 1 14.3335 1C13.7677 1 13.2251 1.25934 12.825 1.72096C12.425 2.18259 12.2002 2.80869 12.2002 3.46152V3.46152Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M13.133 15.7692L13.2667 17H15.4001L15.9334 12.0769H17.0001V10.2308C17.0001 9.65935 16.8622 9.09921 16.6018 8.61313C16.3414 8.12705 15.9689 7.73423 15.526 7.47868C15.083 7.22313 14.5871 7.11496 14.0939 7.16627C13.6007 7.21759 13.1296 7.42637 12.7334 7.76923"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M1.5332 3.46152C1.5332 4.11436 1.75796 4.74046 2.15804 5.20208C2.55812 5.66371 3.10074 5.92305 3.66654 5.92305C4.23233 5.92305 4.77495 5.66371 5.17503 5.20208C5.57511 4.74046 5.79987 4.11436 5.79987 3.46152C5.79987 2.80869 5.57511 2.18259 5.17503 1.72096C4.77495 1.25934 4.23233 1 3.66654 1C3.10074 1 2.55812 1.25934 2.15804 1.72096C1.75796 2.18259 1.5332 2.80869 1.5332 3.46152V3.46152Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.86702 15.7692L4.73333 17H2.6L2.06667 12.0769H1V10.2308C1 9.65935 1.13791 9.09921 1.39826 8.61313C1.65862 8.12705 2.03115 7.73423 2.4741 7.47868C2.91704 7.22313 3.41292 7.11496 3.90615 7.16627C4.39938 7.21759 4.87048 7.42637 5.26666 7.76923"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M7.40039 6.53845C7.40039 7.02808 7.56896 7.49765 7.86902 7.84387C8.16908 8.19009 8.57605 8.3846 9.00039 8.3846C9.42474 8.3846 9.83171 8.19009 10.1318 7.84387C10.4318 7.49765 10.6004 7.02808 10.6004 6.53845C10.6004 6.04881 10.4318 5.57924 10.1318 5.23302C9.83171 4.8868 9.42474 4.69229 9.00039 4.69229C8.57605 4.69229 8.16908 4.8868 7.86902 5.23302C7.56896 5.57924 7.40039 6.04881 7.40039 6.53845V6.53845Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M8.99967 9.61534C8.29243 9.61534 7.61415 9.93952 7.11406 10.5166C6.61396 11.0936 6.33301 11.8762 6.33301 12.6923V13.3077H7.39967L7.93301 17H8.99967"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M9 9.61534C9.70724 9.61534 10.3855 9.93952 10.8856 10.5166C11.3857 11.0936 11.6667 11.8762 11.6667 12.6923V13.3077H10.6L10.0667 17H9"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title  class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              ">
              Members
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :disabled="!activeEntity()" v-if="user.role == 'admin' || user.role == 'moderator'" two-line :class="watchActiveItem == 'invoices'
            ? 'pointer active'
            : 'pointer inactive'
          " @click="activeItem('invoices')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2 '
              : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            ">
            <svg width="18" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 2.6738L2.2 4.18767V7.7619C2.168 9.11254 3.7336 10.3224 5.1992 11.5642" stroke="#707070"
                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.58509 6.78734L6.00029 8.2372C6.08959 8.32888 6.19606 8.40207 6.31364 8.45259C6.43122 8.50311 6.5576 8.52997 6.68556 8.53164C6.81352 8.53331 6.94055 8.50976 7.05941 8.46233C7.17827 8.4149 7.28662 8.34452 7.37829 8.2552C7.46995 8.16589 7.54313 8.05939 7.59364 7.94179C7.64415 7.82419 7.67101 7.69779 7.67268 7.56981C7.67435 7.44182 7.6508 7.31477 7.60338 7.19589C7.55596 7.07701 7.48558 6.96863 7.39629 6.87696L5.19549 4.63096V3.46035C5.07625 2.7589 4.80958 2.09074 4.41309 1.5"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M5.2002 7.41145V17.1028C5.2002 17.4211 5.32662 17.7264 5.55167 17.9515C5.77671 18.1765 6.08194 18.303 6.4002 18.303H12.4002"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M18.9996 18.3286L17.7996 16.8147V13.2413C17.8276 12.0739 16.6628 11.0121 15.3996 9.94472V14.1999L13.9996 12.766C13.8193 12.5809 13.5728 12.4749 13.3144 12.4716C13.0559 12.4682 12.8067 12.5676 12.6216 12.748C12.4365 12.9284 12.3306 13.1749 12.3272 13.4334C12.3239 13.6919 12.4233 13.9411 12.6036 14.1263L14.8076 16.3731V17.5429C14.9275 18.2432 15.1941 18.9101 15.59 19.5"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10.782 15.3025H7.59961" stroke="#707070" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M8.79961 12.3019H7.59961" stroke="#707070" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M16.6004 5.10064C16.6004 5.81278 16.3893 6.50893 15.9937 7.10105C15.5981 7.69317 15.0359 8.15467 14.3781 8.4272C13.7202 8.69972 12.9964 8.77103 12.2981 8.6321C11.5997 8.49316 10.9583 8.15024 10.4548 7.64668C9.95134 7.14312 9.60847 6.50155 9.46957 5.80309C9.33066 5.10463 9.40195 4.38067 9.67443 3.72273C9.9469 3.0648 10.4083 2.50246 11.0003 2.10682C11.5924 1.71117 12.2884 1.5 13.0004 1.5"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M16.5996 2.39938L13.3196 5.67997C13.2777 5.72216 13.2278 5.75564 13.1729 5.77849C13.118 5.80134 13.0591 5.8131 12.9996 5.8131C12.9402 5.8131 12.8813 5.80134 12.8263 5.77849C12.7714 5.75564 12.7216 5.72216 12.6796 5.67997L11.6484 4.64778"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              ">
              Invoices
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :disabled="!activeEntity()" v-if="user.role == 'admin' || user.role == 'moderator'" two-line :class="watchActiveItem == 'providers'
            ? 'pointer active'
            : 'pointer inactive'
          " @click="activeItem('providers')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2 '
              : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            ">
            <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.84668 3.1472C7.84668 3.71667 8.07356 4.26282 8.47741 4.6655C8.88126 5.06818 9.429 5.2944 10.0001 5.2944C10.5713 5.2944 11.119 5.06818 11.5229 4.6655C11.9267 4.26282 12.1536 3.71667 12.1536 3.1472C12.1536 2.57773 11.9267 2.03158 11.5229 1.6289C11.119 1.22622 10.5713 1 10.0001 1C9.429 1 8.88126 1.22622 8.47741 1.6289C8.07356 2.03158 7.84668 2.57773 7.84668 3.1472V3.1472Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M12.5654 10.5002C12.8102 10.4963 13.0523 10.5515 13.2709 10.6613C13.4896 10.7711 13.6782 10.9322 13.8208 11.1306C13.9633 11.329 14.0555 11.559 14.0893 11.8007C14.1232 12.0425 14.0978 12.2888 14.0152 12.5186"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 5.29395V18.9995" stroke="#707070" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M11.9746 4H18.3042C20.452 4 17.3414 9.1624 12.8708 7.44" stroke="#707070" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8.0233 4H1.69532C-0.451716 4 2.65812 9.1624 7.1287 7.44" stroke="#707070" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M7.4342 10.5001C7.03072 10.496 6.64208 10.6516 6.35347 10.9328C6.06486 11.214 5.89982 11.5978 5.89453 12.0001C5.89666 12.4099 6.05844 12.803 6.34572 13.0961C6.633 13.3893 7.0233 13.5597 7.4342 13.5713H11.5389C11.9473 13.5713 12.3389 13.733 12.6276 14.0209C12.9164 14.3088 13.0786 14.6993 13.0786 15.1065C13.0786 15.5136 12.9164 15.9041 12.6276 16.192C12.3389 16.4799 11.9473 16.6417 11.5389 16.6417H8.71713C8.36869 16.6496 8.03769 16.7953 7.79695 17.0466C7.55621 17.2979 7.42544 17.6342 7.4334 17.9817C7.43239 18.1681 7.46966 18.3528 7.54293 18.5244C7.6162 18.696 7.72391 18.8508 7.85944 18.9793"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              ">
              Providers
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :disabled="!activeEntity()" v-if="user.role == 'admin' || user.role == 'moderator'" two-line :class="watchActiveItem == 'locations'
            ? 'pointer active'
            : 'pointer inactive'
          " @click="activeItem('locations')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2 '
              : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            ">
            <svg width="14" height="16" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 15.7273C7 15.7273 1 9.18182 1 6.23636C1 3.37273 3.71874 1 7 1C10.2812 1 13 3.37273 13 6.23636C13 9.18182 7 15.7273 7 15.7273Z"
                stroke="#707070" stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round" />
              <path d="M11.6875 19H2.3125" stroke="#707070" stroke-width="2" stroke-miterlimit="10"
                stroke-linejoin="round" />
              <path
                d="M7.00001 8.36392C5.65385 8.36392 4.5625 7.41152 4.5625 6.23666C4.5625 5.06179 5.65385 4.10938 7.00001 4.10938C8.34617 4.10938 9.43751 5.06179 9.43751 6.23666C9.43751 7.41152 8.34617 8.36392 7.00001 8.36392Z"
                fill="#707070" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              ">
              Locations
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :disabled="!activeEntity()" v-if="user.role == 'admin' || user.role == 'moderator'" two-line :class="watchActiveItem == 'rates' ? 'pointer active' : 'pointer inactive'
          " @click="activeItem('rates')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2 '
              : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            ">
            <svg width="18" height="18" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.5625 17.5625C13.9808 17.5625 17.5625 13.9808 17.5625 9.5625C17.5625 5.14422 13.9808 1.5625 9.5625 1.5625C5.14422 1.5625 1.5625 5.14422 1.5625 9.5625C1.5625 13.9808 5.14422 17.5625 9.5625 17.5625Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M11.6954 6.36234H8.70164C8.53093 6.34836 8.35923 6.37168 8.19845 6.43071C8.03767 6.48973 7.89166 6.58303 7.77055 6.70414C7.64944 6.82525 7.55614 6.97126 7.49711 7.13205C7.43809 7.29283 7.41476 7.46453 7.42875 7.63523C7.42875 9.05745 11.6954 10.0957 11.6954 11.4895C11.6983 11.6574 11.6673 11.8242 11.6044 11.98C11.5415 12.1357 11.4478 12.2772 11.329 12.396C11.2102 12.5147 11.0688 12.6084 10.913 12.6713C10.7573 12.7343 10.5905 12.7652 10.4225 12.7623H7.42875"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.56152 6.3627V4.7627" stroke="#707070" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M9.56152 14.3627V12.7627" stroke="#707070" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              ">
              Rates
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item
          two-line
          :class="
            watchActiveItem == 'vendors' ? 'pointer active' : 'pointer inactive'
          "
          @click="activeItem('vendors')"
        >
          <v-list-item-icon
            class="list-item-icon no-padding"
            :class="
              !mini
                ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2 '
                : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            "
          >
            <svg
              class="mt-1"
              width="16"
              height="16"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6312 12.9863L12.1992 14.4863"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.34375 13.0002H5.30375L8.11175 18.3729C8.21361 18.5636 8.33692 18.7173 8.47436 18.8251C8.61179 18.9329 8.76054 18.9924 8.91175 19.0002C10.5465 16.9945 12.0823 14.7644 13.5037 12.332C13.5917 11.9093 13.4238 11.4866 13.2718 11.132L11.2637 6.71387"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M8.86439 3.29097L8.58439 2.88188C8.29678 2.53301 7.95338 2.34266 7.60039 2.33643C7.38661 2.33566 7.17475 2.4051 6.97639 2.54098L3.40039 4.96824"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M1 2.63624H2.6C2.80154 2.63514 2.99589 2.76373 3.14421 2.99632C3.29253 3.22891 3.38388 3.54835 3.4 3.89078V12.6453C3.38198 12.9853 3.2898 13.3014 3.14168 13.5311C2.99356 13.7609 2.80027 13.8876 2.6 13.8862H1"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18.9996 13.8862H17.3996C17.1993 13.8876 17.0061 13.7609 16.8579 13.5311C16.7098 13.3014 16.6176 12.9853 16.5996 12.6453V3.89078C16.6157 3.54835 16.7071 3.22891 16.8554 2.99632C17.0037 2.76373 17.1981 2.63514 17.3996 2.63624H18.9996"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12.2001 6.18188L9.44014 7.73642C9.13574 7.91784 8.80248 7.89803 8.50623 7.68089C8.20998 7.46376 7.97229 7.06509 7.84014 6.56369C7.69777 6.04133 7.67992 5.44515 7.79035 4.90079C7.90078 4.35643 8.13088 3.9064 8.43214 3.64553L11.1201 1.34099C11.3827 1.11328 11.6735 0.996365 11.9681 1.00009C12.1862 1.00259 12.4025 1.06711 12.6081 1.19099L16.6081 3.6728"
                stroke="#707070"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title
              class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              "
            >
              Vendors
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </div>
      <div>
        <v-divider></v-divider>
        <v-list-item two-line v-if="!mini" class="mb-2">
          <span class="blue--text mt-1 ml-1 font-bold" style="font-size: 12px">
            Powered by:
          </span>

          <v-list-item-content class="no-padding pointer justify-center mt-2 mb-2">
            <v-list-item-title class="d-flex mb-3 align-center text-center text-gray-darker">
              <img src="/newapalylogoblue.png" alt="" class="mt-1 ml-1" width="110" height="45" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line v-else class="mt-4">
          <v-list-item-content class="no-padding pointer">
            <v-list-item-title class="d-flex mb-3 align-center text-center text-gray-darker">
              <img src="/newapalylogoblue.png" class="ml-1" width="95" height="45" alt="" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-navigation-drawer>
    <!-- Mobile -->
    <v-navigation-drawer v-else permanent class="white-sidebar-onmobile mt-3">
      <div class="ml-1">
        <v-divider></v-divider>
        <v-list-item two-line class="mt-3" :class="watchActiveItem == 'home' ? 'pointer active' : 'pointer inactive'
          " @click="activeItem('home')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-4 mt-lg-3 mt-md-2'
              : 'ml-4 mt-xl-4 mt-lg-3 mt-md-2'
            ">
            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.7319 6.49L9.00356 0.659668L1.26809 6.49C1.10817 6.63755 1.01201 6.84157 1 7.05881V15.9622C1.00056 16.1464 1.07402 16.323 1.20432 16.4532C1.33462 16.5835 1.51119 16.657 1.69547 16.6575H6.33333V12.9268C6.33333 12.2197 6.61429 11.5415 7.11438 11.0415C7.61448 10.5414 8.29276 10.2605 9 10.2605C9.70724 10.2605 10.3855 10.5414 10.8856 11.0415C11.3857 11.5415 11.6667 12.2197 11.6667 12.9268V16.6597H16.3038C16.4882 16.6591 16.6648 16.5857 16.7952 16.4554C16.9256 16.3252 16.9993 16.1486 17 15.9643V7.05881C16.9877 6.84165 16.8916 6.63773 16.7319 6.49Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
                mt-1
              ">
              Home
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line :class="watchActiveItem == 'solutions'
            ? 'pointer active'
            : 'pointer inactive'
          " @click="activeItem('solutions')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-4 mt-lg-3 mt-md-2'
              : 'ml-4 mt-xl-4 mt-lg-3 mt-md-2'
            ">
        <svg width="15" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path 
                d="M23.8388 9.00414L20.4113 3.00414C20.1975 2.63289 19.8 2.40039 19.3688 2.40039H4.63125C4.2 2.40039 3.8025 2.63289 3.58875 3.00414L0.161248 9.00414C-0.296252 9.80664 0.277498 10.8004 1.2 10.8004H2.4V20.3441C2.4 21.0379 2.93625 21.6004 3.6 21.6004H13.2C13.8638 21.6004 14.4 21.0379 14.4 20.3441V10.8004H20.4V21.3004C20.4 21.4654 20.535 21.6004 20.7 21.6004H21.3C21.465 21.6004 21.6 21.4654 21.6 21.3004V10.8004H22.8C23.7225 10.8004 24.2963 9.80664 23.8388 9.00414ZM13.2 20.3441C13.2 20.3779 13.1888 20.3966 13.1925 20.4004L3.6225 20.4079C3.6225 20.4079 3.6 20.3891 3.6 20.3441V15.6004H13.2V20.3441ZM13.2 14.4004H3.6V10.8004H13.2V14.4004ZM1.20375 9.60039L4.63125 3.60039H19.3688L22.8 9.60039H1.20375Z"
                fill="#707070" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
                mt-1
              ">
              Marketplace
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line :class="watchActiveItem == 'clients' ? 'pointer active' : 'pointer inactive'
          " @click="activeItem('clients')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2'
              : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            ">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.99991 12.7334H1.99998C1.73477 12.7334 1.48042 12.8458 1.29289 13.0458C1.10535 13.2459 1 13.5172 1 13.8001V17.0001H5.99991V12.7334Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M14.995 12.7334H10.9951V17.0001H15.995V13.8001C15.995 13.5172 15.8897 13.2459 15.7021 13.0458C15.5146 12.8458 15.2603 12.7334 14.995 12.7334Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M9.99993 8.4668H6.99998C6.73477 8.4668 6.48042 8.57918 6.29289 8.77922C6.10535 8.97925 6 9.25057 6 9.53346V17.0001H10.9999V9.53346C10.9999 9.25057 10.8946 8.97925 10.707 8.77922C10.5195 8.57918 10.2651 8.4668 9.99993 8.4668Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M6.74707 2.86667C6.74707 3.36174 6.93144 3.83653 7.25962 4.1866C7.58781 4.53667 8.03292 4.73333 8.49704 4.73333C8.96116 4.73333 9.40627 4.53667 9.73445 4.1866C10.0626 3.83653 10.247 3.36174 10.247 2.86667C10.247 2.3716 10.0626 1.8968 9.73445 1.54673C9.40627 1.19667 8.96116 1 8.49704 1C8.03292 1 7.58781 1.19667 7.25962 1.54673C6.93144 1.8968 6.74707 2.3716 6.74707 2.86667V2.86667Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M10.6653 6.333C10.3981 5.99838 10.066 5.72964 9.69193 5.54536C9.31785 5.36107 8.91071 5.26563 8.49866 5.26562C8.08661 5.26563 7.67947 5.36107 7.30539 5.54536C6.93131 5.72964 6.5992 5.99838 6.33203 6.333"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M12.0791 7.66647C12.0791 8.16154 12.2635 8.63634 12.5917 8.9864C12.9198 9.33647 13.3649 9.53314 13.8291 9.53314C14.2932 9.53314 14.7383 9.33647 15.0665 8.9864C15.3947 8.63634 15.579 8.16154 15.579 7.66647C15.579 7.1714 15.3947 6.69661 15.0665 6.34654C14.7383 5.99647 14.2932 5.7998 13.8291 5.7998C13.3649 5.7998 12.9198 5.99647 12.5917 6.34654C12.2635 6.69661 12.0791 7.1714 12.0791 7.66647V7.66647Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M15.9999 11.1331C15.6401 10.6818 15.1642 10.353 14.631 10.1873C14.0978 10.0216 13.5307 10.0263 13 10.2008"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M1.41504 7.66647C1.41504 7.91161 1.4603 8.15434 1.54825 8.38081C1.63619 8.60729 1.76509 8.81307 1.92759 8.9864C2.09009 9.15974 2.28301 9.29724 2.49532 9.39105C2.70764 9.48486 2.9352 9.53314 3.16501 9.53314C3.39482 9.53314 3.62237 9.48486 3.83469 9.39105C4.04701 9.29724 4.23992 9.15974 4.40242 8.9864C4.56492 8.81307 4.69382 8.60729 4.78177 8.38081C4.86971 8.15434 4.91497 7.91161 4.91497 7.66647C4.91497 7.42134 4.86971 7.1786 4.78177 6.95213C4.69382 6.72565 4.56492 6.51987 4.40242 6.34654C4.23992 6.1732 4.04701 6.03571 3.83469 5.9419C3.62237 5.84809 3.39482 5.7998 3.16501 5.7998C2.9352 5.7998 2.70764 5.84809 2.49532 5.9419C2.28301 6.03571 2.09009 6.1732 1.92759 6.34654C1.76509 6.51987 1.63619 6.72565 1.54825 6.95213C1.4603 7.1786 1.41504 7.42134 1.41504 7.66647V7.66647Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M1 11.1331C1.26773 10.7992 1.59996 10.531 1.97392 10.3469C2.34789 10.1628 2.75475 10.0671 3.16663 10.0664C3.44799 10.0666 3.72774 10.1117 3.99661 10.2001"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
                mt-1
              ">
              Clients
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line :class="watchActiveItem == 'members' ? 'pointer active' : 'pointer inactive'
          " @click="activeItem('members')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2 '
              : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            ">
            <svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.2002 3.46152C12.2002 4.11436 12.425 4.74046 12.825 5.20208C13.2251 5.66371 13.7677 5.92305 14.3335 5.92305C14.8993 5.92305 15.4419 5.66371 15.842 5.20208C16.2421 4.74046 16.4669 4.11436 16.4669 3.46152C16.4669 2.80869 16.2421 2.18259 15.842 1.72096C15.4419 1.25934 14.8993 1 14.3335 1C13.7677 1 13.2251 1.25934 12.825 1.72096C12.425 2.18259 12.2002 2.80869 12.2002 3.46152V3.46152Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M13.133 15.7692L13.2667 17H15.4001L15.9334 12.0769H17.0001V10.2308C17.0001 9.65935 16.8622 9.09921 16.6018 8.61313C16.3414 8.12705 15.9689 7.73423 15.526 7.47868C15.083 7.22313 14.5871 7.11496 14.0939 7.16627C13.6007 7.21759 13.1296 7.42637 12.7334 7.76923"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M1.5332 3.46152C1.5332 4.11436 1.75796 4.74046 2.15804 5.20208C2.55812 5.66371 3.10074 5.92305 3.66654 5.92305C4.23233 5.92305 4.77495 5.66371 5.17503 5.20208C5.57511 4.74046 5.79987 4.11436 5.79987 3.46152C5.79987 2.80869 5.57511 2.18259 5.17503 1.72096C4.77495 1.25934 4.23233 1 3.66654 1C3.10074 1 2.55812 1.25934 2.15804 1.72096C1.75796 2.18259 1.5332 2.80869 1.5332 3.46152V3.46152Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.86702 15.7692L4.73333 17H2.6L2.06667 12.0769H1V10.2308C1 9.65935 1.13791 9.09921 1.39826 8.61313C1.65862 8.12705 2.03115 7.73423 2.4741 7.47868C2.91704 7.22313 3.41292 7.11496 3.90615 7.16627C4.39938 7.21759 4.87048 7.42637 5.26666 7.76923"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M7.40039 6.53845C7.40039 7.02808 7.56896 7.49765 7.86902 7.84387C8.16908 8.19009 8.57605 8.3846 9.00039 8.3846C9.42474 8.3846 9.83171 8.19009 10.1318 7.84387C10.4318 7.49765 10.6004 7.02808 10.6004 6.53845C10.6004 6.04881 10.4318 5.57924 10.1318 5.23302C9.83171 4.8868 9.42474 4.69229 9.00039 4.69229C8.57605 4.69229 8.16908 4.8868 7.86902 5.23302C7.56896 5.57924 7.40039 6.04881 7.40039 6.53845V6.53845Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M8.99967 9.61534C8.29243 9.61534 7.61415 9.93952 7.11406 10.5166C6.61396 11.0936 6.33301 11.8762 6.33301 12.6923V13.3077H7.39967L7.93301 17H8.99967"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M9 9.61534C9.70724 9.61534 10.3855 9.93952 10.8856 10.5166C11.3857 11.0936 11.6667 11.8762 11.6667 12.6923V13.3077H10.6L10.0667 17H9"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              ">
              Members
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line :class="watchActiveItem == 'invoices'
            ? 'pointer active'
            : 'pointer inactive'
          " @click="activeItem('invoices')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2 '
              : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            ">
            <svg width="18" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 2.6738L2.2 4.18767V7.7619C2.168 9.11254 3.7336 10.3224 5.1992 11.5642" stroke="#707070"
                stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M4.58509 6.78734L6.00029 8.2372C6.08959 8.32888 6.19606 8.40207 6.31364 8.45259C6.43122 8.50311 6.5576 8.52997 6.68556 8.53164C6.81352 8.53331 6.94055 8.50976 7.05941 8.46233C7.17827 8.4149 7.28662 8.34452 7.37829 8.2552C7.46995 8.16589 7.54313 8.05939 7.59364 7.94179C7.64415 7.82419 7.67101 7.69779 7.67268 7.56981C7.67435 7.44182 7.6508 7.31477 7.60338 7.19589C7.55596 7.07701 7.48558 6.96863 7.39629 6.87696L5.19549 4.63096V3.46035C5.07625 2.7589 4.80958 2.09074 4.41309 1.5"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M5.2002 7.41145V17.1028C5.2002 17.4211 5.32662 17.7264 5.55167 17.9515C5.77671 18.1765 6.08194 18.303 6.4002 18.303H12.4002"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M18.9996 18.3286L17.7996 16.8147V13.2413C17.8276 12.0739 16.6628 11.0121 15.3996 9.94472V14.1999L13.9996 12.766C13.8193 12.5809 13.5728 12.4749 13.3144 12.4716C13.0559 12.4682 12.8067 12.5676 12.6216 12.748C12.4365 12.9284 12.3306 13.1749 12.3272 13.4334C12.3239 13.6919 12.4233 13.9411 12.6036 14.1263L14.8076 16.3731V17.5429C14.9275 18.2432 15.1941 18.9101 15.59 19.5"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10.782 15.3025H7.59961" stroke="#707070" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M8.79961 12.3019H7.59961" stroke="#707070" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path
                d="M16.6004 5.10064C16.6004 5.81278 16.3893 6.50893 15.9937 7.10105C15.5981 7.69317 15.0359 8.15467 14.3781 8.4272C13.7202 8.69972 12.9964 8.77103 12.2981 8.6321C11.5997 8.49316 10.9583 8.15024 10.4548 7.64668C9.95134 7.14312 9.60847 6.50155 9.46957 5.80309C9.33066 5.10463 9.40195 4.38067 9.67443 3.72273C9.9469 3.0648 10.4083 2.50246 11.0003 2.10682C11.5924 1.71117 12.2884 1.5 13.0004 1.5"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M16.5996 2.39938L13.3196 5.67997C13.2777 5.72216 13.2278 5.75564 13.1729 5.77849C13.118 5.80134 13.0591 5.8131 12.9996 5.8131C12.9402 5.8131 12.8813 5.80134 12.8263 5.77849C12.7714 5.75564 12.7216 5.72216 12.6796 5.67997L11.6484 4.64778"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              ">
              Invoices
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line :class="watchActiveItem == 'providers'
            ? 'pointer active'
            : 'pointer inactive'
          " @click="activeItem('providers')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2 '
              : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            ">
            <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.84668 3.1472C7.84668 3.71667 8.07356 4.26282 8.47741 4.6655C8.88126 5.06818 9.429 5.2944 10.0001 5.2944C10.5713 5.2944 11.119 5.06818 11.5229 4.6655C11.9267 4.26282 12.1536 3.71667 12.1536 3.1472C12.1536 2.57773 11.9267 2.03158 11.5229 1.6289C11.119 1.22622 10.5713 1 10.0001 1C9.429 1 8.88126 1.22622 8.47741 1.6289C8.07356 2.03158 7.84668 2.57773 7.84668 3.1472V3.1472Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M12.5654 10.5002C12.8102 10.4963 13.0523 10.5515 13.2709 10.6613C13.4896 10.7711 13.6782 10.9322 13.8208 11.1306C13.9633 11.329 14.0555 11.559 14.0893 11.8007C14.1232 12.0425 14.0978 12.2888 14.0152 12.5186"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M10 5.29395V18.9995" stroke="#707070" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M11.9746 4H18.3042C20.452 4 17.3414 9.1624 12.8708 7.44" stroke="#707070" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
              <path d="M8.0233 4H1.69532C-0.451716 4 2.65812 9.1624 7.1287 7.44" stroke="#707070" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M7.4342 10.5001C7.03072 10.496 6.64208 10.6516 6.35347 10.9328C6.06486 11.214 5.89982 11.5978 5.89453 12.0001C5.89666 12.4099 6.05844 12.803 6.34572 13.0961C6.633 13.3893 7.0233 13.5597 7.4342 13.5713H11.5389C11.9473 13.5713 12.3389 13.733 12.6276 14.0209C12.9164 14.3088 13.0786 14.6993 13.0786 15.1065C13.0786 15.5136 12.9164 15.9041 12.6276 16.192C12.3389 16.4799 11.9473 16.6417 11.5389 16.6417H8.71713C8.36869 16.6496 8.03769 16.7953 7.79695 17.0466C7.55621 17.2979 7.42544 17.6342 7.4334 17.9817C7.43239 18.1681 7.46966 18.3528 7.54293 18.5244C7.6162 18.696 7.72391 18.8508 7.85944 18.9793"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              ">
              Providers
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line :class="watchActiveItem == 'locations'
            ? 'pointer active'
            : 'pointer inactive'
          " @click="activeItem('locations')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2 '
              : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            ">
            <svg width="14" height="16" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7 15.7273C7 15.7273 1 9.18182 1 6.23636C1 3.37273 3.71874 1 7 1C10.2812 1 13 3.37273 13 6.23636C13 9.18182 7 15.7273 7 15.7273Z"
                stroke="#707070" stroke-width="2" stroke-miterlimit="10" stroke-linejoin="round" />
              <path d="M11.6875 19H2.3125" stroke="#707070" stroke-width="2" stroke-miterlimit="10"
                stroke-linejoin="round" />
              <path
                d="M7.00001 8.36392C5.65385 8.36392 4.5625 7.41152 4.5625 6.23666C4.5625 5.06179 5.65385 4.10938 7.00001 4.10938C8.34617 4.10938 9.43751 5.06179 9.43751 6.23666C9.43751 7.41152 8.34617 8.36392 7.00001 8.36392Z"
                fill="#707070" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              ">
              Locations
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line :class="watchActiveItem == 'rates' ? 'pointer active' : 'pointer inactive'
          " @click="activeItem('rates')">
          <v-list-item-icon class="list-item-icon no-padding" :class="!mini
              ? 'ml-xl-4 ml-lg-4  ml-md-1 mt-xl-3 mt-lg-3 mt-md-2 '
              : 'ml-4 mt-xl-3 mt-lg-3 mt-md-2'
            ">
            <svg width="18" height="18" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.5625 17.5625C13.9808 17.5625 17.5625 13.9808 17.5625 9.5625C17.5625 5.14422 13.9808 1.5625 9.5625 1.5625C5.14422 1.5625 1.5625 5.14422 1.5625 9.5625C1.5625 13.9808 5.14422 17.5625 9.5625 17.5625Z"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path
                d="M11.6954 6.36234H8.70164C8.53093 6.34836 8.35923 6.37168 8.19845 6.43071C8.03767 6.48973 7.89166 6.58303 7.77055 6.70414C7.64944 6.82525 7.55614 6.97126 7.49711 7.13205C7.43809 7.29283 7.41476 7.46453 7.42875 7.63523C7.42875 9.05745 11.6954 10.0957 11.6954 11.4895C11.6983 11.6574 11.6673 11.8242 11.6044 11.98C11.5415 12.1357 11.4478 12.2772 11.329 12.396C11.2102 12.5147 11.0688 12.6084 10.913 12.6713C10.7573 12.7343 10.5905 12.7652 10.4225 12.7623H7.42875"
                stroke="#707070" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9.56152 6.3627V4.7627" stroke="#707070" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
              <path d="M9.56152 14.3627V12.7627" stroke="#707070" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </v-list-item-icon>
          <v-list-item-content class="no-padding">
            <v-list-item-title class="
                d-flex
                align-center
                font-quicksand-medium-12
                text-gray-darker
              ">
              Rates
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "Tables",
  data() {
    return {
      miniVersion: true,
      item: "home",
      user: {},
      items: [
        {
          icon: "fas fa-home",
          title: "Home",
        },
        {
          icon: "fas fa-users",
          title: "Clients",
        },
        {
          icon: "fas fa-cog",
          title: "Settings",
        },
      ],
      route: this.$route.path,
    };
  },
  props: {
    windowWidth: {
      type: Boolean,
    },
    mini: {
      type: Boolean,
    },
  },

  computed: {
    routeParams() {
      return this.$route.params.name;
    },
    path() {
      return this.$route.path.replace(/%20/g, " ");
    },
    singleUser() {
      return this.$store.getters.getUser;
    },
    watchActiveItem() {
      if (this.$route.path.includes("/dpc-providers/account/users")) {
        this.item = "account/users";
      } else if (this.$route.path.includes("/dpc-providers/account/settings")) {
        this.item = "account/settings";
      } else if (this.$route.path.includes("/dpc-providers/account/contract")) {
        this.item = "account/contract";
      } else if (this.$route.path.includes("/dpc-providers/account/bank")) {
        this.item = "account/bank";
      } else if (
        this.$route.path.includes("/dpc-providers/account/locations")
      ) {
        this.item = "account/locations";
      } else if (
        this.$route.path.includes("/dpc-providers/account/providers")
      ) {
        this.item = "account/providers";
      } else if (this.$route.path.includes("/dpc-providers/account/clients")) {
        this.item = "account/clients";
      } else if (this.$route.path.includes("/dpc-providers/account/rates")) {
        this.item = "account/rates";
      } else if (this.$route.path.includes(this.$route.path.split("/")[2])) {
        this.item = this.$route.path.split("/")[2];
      }

      return this.item;
    },
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('user'))
    if (this.$route.path.includes('dpc-providers') && this.$route.query.token) {
      this.user.role = 'admin'
    }


  },
  methods: {
    activeItem(tab) {
      if (tab == "members") {
        this.$store.commit("setFilterMember", "");
      }
      if (this.$route.path != `/dpc-providers/${tab}`) {
        this.$router.push(`/dpc-providers/${tab}`);
      }
      if (!this.windowWidth) {
        this.$emit("closeSideBar");
      }
    },
    activeEntity() {
        return (this.user.entity && this.user.entity.status === "active") || this.user.superAdmin
    },
  },
};
</script>
<style>
.disabled {
    cursor: not-allowed;
}
</style>
